<template>
	<div class="grid grid-cols-2 md:grid-cols-4 gap-4">
		<div class="grid gap-4">
			<div>
				<img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image.jpg" alt="">
			</div>
			<div>
				<img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-1.jpg" alt="">
			</div>
			<div>
				<img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-2.jpg" alt="">
			</div>
		</div>
		<div class="grid gap-4">
			<div>
				<img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-3.jpg" alt="">
			</div>
			<div>
				<img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-4.jpg" alt="">
			</div>
			<div>
				<img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-5.jpg" alt="">
			</div>
		</div>
		<div class="grid gap-4">
			<div>
				<img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-6.jpg" alt="">
			</div>
			<div>
				<img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-7.jpg" alt="">
			</div>
			<div>
				<img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-8.jpg" alt="">
			</div>
		</div>
		<div class="grid gap-4">
			<div>
				<img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-9.jpg" alt="">
			</div>
			<div>
				<img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-10.jpg" alt="">
			</div>
			<div>
				<img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-11.jpg" alt="">
			</div>
		</div>
	</div>



<!--	-->
<!--	<div class="uk-card " v-for="item in kitchen3.projects" :key="data.id">-->
<!--		<img :data-src="item.srcMin" alt="" uk-img >-->
<!--	</div>-->





<!--	<div-->
<!--			class="uk-position-relative uk-visible-toggle uk-light uk-box-shadow-hover-large"-->
<!--			tabindex="-1"-->
<!--			uk-slideshow="animation: fade; autoplay: true; pause-on-hover: false; finite: false;"-->
<!--			:data-autoplay-interval="autoplay"-->
<!--	>-->
<!--		<ul class="uk-slideshow-items" uk-lightbox>-->
<!--			<li v-for="item in kitchen3.projects" :key="data.id">-->
<!--				<div-->
<!--						class="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-left"-->
<!--				>-->
<!--					<a :href="item.src" class="td">-->
<!--						<q-img-->
<!--								:src="item.srcMin"-->
<!--								:ratio="16 / 9"-->
<!--								loading="eager"-->
<!--								width="360px"-->
<!--								no-native-menu-->
<!--								spinner-color="lime-12"-->
<!--								uk-cover-->
<!--								:alt="item.alt"-->
<!--						/>-->
<!--					</a>-->
<!--				</div>-->

<!--				<div class="uk-position-center uk-hidden-hover">-->
<!--					<a :href="item.src" class="td">-->
<!--						<q-icon class="text-cyan-13 text-h3" name="zoom_in" />-->
<!--					</a>-->
<!--				</div>-->

<!--				<div class="uk-position-bottom uk-text-center">-->
<!--					<div class="uk-transition-slide-bottom-small">-->
<!--						<div class="uk-padding-small">-->
<!--							<q-btn-->
<!--									class="text-black"-->
<!--									color="cyan-13"-->
<!--									label="Хочу такую-же"-->
<!--									@click="sm = !sm"-->
<!--							/>-->
<!--						</div>-->
<!--					</div>-->
<!--				</div>-->

<!--				<div class="uk-position-top-left uk-position-small">-->
<!--          <span-->
<!--		          style="padding: 3px 3px 3px 3px"-->
<!--		          class="bg-teal-9 text-white uk-border-rounded"-->
<!--          >Цена на момент заказа: <b>{{ priceNew }} ₽</b></span-->
<!--          >-->
<!--				</div>-->
<!--			</li>-->
<!--		</ul>-->

<!--		<a-->
<!--				class="uk-position-center-left uk-position-small"-->
<!--				href="#"-->
<!--				style="text-decoration: none"-->
<!--				uk-slideshow-item="previous"-->
<!--		><q-icon-->
<!--				class="bg-teal-9 text-white uk-border-circle q-pa-sm"-->
<!--				style="font-size: 1.5em"-->
<!--				name="chevron_left"-->
<!--		/></a>-->
<!--		<a-->
<!--				class="uk-position-center-right uk-position-small"-->
<!--				href="#"-->
<!--				style="text-decoration: none"-->
<!--				uk-slideshow-item="next"-->
<!--		><q-icon-->
<!--				class="bg-teal-9 text-white uk-border-circle q-pa-sm"-->
<!--				style="font-size: 1.5em"-->
<!--				name="chevron_right"-->
<!--		/></a>-->
<!--	</div>-->

	<ModalSendPhone :state="sm" v-model="sm"/>
</template>

<script>
import { computed, ref } from "vue";

import User from "../User";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import ModalSendPhone from "./ModalSendPhone";

function getRandomInt(max) {
	return Math.floor(Math.random() * max);
}

function getRandomArbitrary(min, max) {
	return Math.random() * (max - min) + min;
}

export default {
	components: { ModalSendPhone, User },
	props: {
		data: Object,
	},
	name: "productCard",

	setup(props) {
		const router = useRouter();
		const route = useRoute();
		let zero2; // Typescript / ECMAScript syntax, preferred to the "var zero;" syntax.
		if (props.data.id < 10) {
			zero2 = "0";
		} else {
			zero2 = "";
		}
		const idPage = zero2 + props.data.id.toString();
		const sm = ref(false);

		const store = useStore();
		function showModal() {
			sm.value = !sm.value;
		}

		const like = () => {
			console.log("like from " + idPage);
			router.push({ path: `/projects/${idPage}` });
		};

		//Create object

		const priceNew = computed(() => {
			const res = Math.round(props.data.price - props.data.price * 0.3);
			return res;
		});

		const kitchen3 = {
			projects: {},
		};

		//Iterate and add properties
		for (let i = 1; i < props.data.filesCount + 1; i++) {
			let zero; // Typescript / ECMAScript syntax, preferred to the "var zero;" syntax.
			if (i < 10) {
				zero = "0";
			} else {
				zero = "";
			}
			const b = zero + i.toString();

			kitchen3.projects[b] = {
				src: "/img/newKitchen/"  + props.data.id + ".png",
				srcMin:
						"/img/newKitchen/"  + props.data.id + ".png",
				name: i,
			};
		}

		return {
			slide: ref(1),
			fullscreen: ref(false),
			autoplay: ref(getRandomArbitrary(3000, 6000)),
			kitchen3,
			like,
			idPage,
			priceNew,
			showModal,
			sm,
		};
	},
};
</script>

<style scoped>
.td {
	text-decoration: none !important;
}
</style>
