<template>
	<h2-title :title="title"/>


	<div class="uk-child-width-1-2@s uk-child-width-1-3@m" uk-grid="masonry: true">
		<div v-for="data in projectLoaded" :key="data.id">
			<product-card :data="data"/>
			<div class="uk-card uk-card-default uk-flex uk-flex-center uk-flex-middle" style="height: 100px">Item</div>
		</div>
		<div>
			<div class="uk-card uk-card-default uk-flex uk-flex-center uk-flex-middle" style="height: 130px">Item</div>
		</div>
		<div>
			<div class="uk-card uk-card-default uk-flex uk-flex-center uk-flex-middle" style="height: 150px">Item</div>
		</div>
		<div>
			<div class="uk-card uk-card-default uk-flex uk-flex-center uk-flex-middle" style="height: 160px">Item</div>
		</div>
		<div>
			<div class="uk-card uk-card-default uk-flex uk-flex-center uk-flex-middle" style="height: 120px">Item</div>
		</div>
		<div>
			<div class="uk-card uk-card-default uk-flex uk-flex-center uk-flex-middle" style="height: 140px">Item</div>
		</div>
		<div>
			<div class="uk-card uk-card-default uk-flex uk-flex-center uk-flex-middle" style="height: 200px">Item</div>
		</div>
		<div>
			<div class="uk-card uk-card-default uk-flex uk-flex-center uk-flex-middle" style="height: 180px">Item</div>
		</div>
		<div>
			<div class="uk-card uk-card-default uk-flex uk-flex-center uk-flex-middle" style="height: 140px">Item</div>
		</div>
	</div>




	<!--  <div id="photo" class="row justify-center">-->
	<!--    <div class="col-lg-3 col-xl-3 col-md-5 col-sm-5 col-xs-12 q-ma-md"  v-for="data in projectLoaded" :key="data.id">-->
	<!--      <product-card :data="data"/>-->
	<!--    </div>-->
	<!--  </div>-->
	<!--  <div class="text-center">-->
	<!--    <q-btn color="primary"-->
	<!--           label="Показать еще"-->
	<!--           @click="loadMore"-->
	<!--           v-if="kitchenData.length >= projectShow"-->
	<!--           class="q-ma-xl"-->
	<!--           size="lg"-->
	<!--    />-->
	<!--  </div>-->


</template>

<script>
import {kitchenData} from '../../../settings/projects.js'
import productCard from "../../UiComponents/productCard";
import H2Title from "../../UiComponents/h2-title";
import {computed, ref} from "vue";
export default {
	name: "dvmGallerey",
	components: {
		H2Title,
		productCard
	},
	props: {
		title:{
			type: String,
			required: true
		}
	},
	setup() {
		const totalComments = kitchenData.length
		const commentsToShow = 3
		const projectShow = ref(6)

		function shuffleArray(array) {
			for (let i = array.length - 1; i > 0; i--) {
				const j = Math.floor(Math.random() * (i + 1));
				const temp = array[i];
				array[i] = array[j];
				array[j] = temp;
			}
			return array;
		}

		const kd = shuffleArray(kitchenData)


		const loadMore = () => {
			if (projectShow.value > kd.length) return
			projectShow.value = projectShow.value + 3
		}
		const projectLoaded = computed(()=> {
			return kd.slice(0, projectShow.value)
		})
		return{
			kitchenData, totalComments, commentsToShow, projectShow, loadMore, projectLoaded, kd
		}
	}
}
</script>

<style scoped>

</style>