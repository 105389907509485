<template>
  <h3-title :title="title"/>
  <div class="uk-grid-match uk-grid-small uk-child-width-1-3@m uk-child-width-1-2@s" uk-grid>

    <div v-for="q in question">
      <div
          class="col-4 uk-box-shadow-hover-large curPoint "
          @click='setqval(q)'
          :key="q.title"

      >
        <q-img
            loading = "lazy"
            fit = "contain"
            :src="q.img"
            :alt="q.title + ' кухня | DVM - Мебель в Краснодаре'"
            class="rounded-borders"
        >
          <div class="absolute-top text-center text-subtitle text-lime-12">
            {{q.title}}
          </div>
          <card-price :oneVal="q.description" :twoVal="fasadAll"/>
          <div class="absolute-center" style="background-color:transparent;" >
            <q-icon
                style="font-size: 5em"
                class="text-primary"
                name="done"
                :class="{'uk-hidden': q.title!==this.$store.state.q3val }"
            />
          </div>
        </q-img>



      </div>

    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import H3Title from "../../../UiComponents/h3-title";
import {computed} from "vue";
import CardPrice from "../components/cardPrice";
export default {
  name: "fasadKitchen",
  components: {CardPrice, H3Title},
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup() {
    const store = useStore()
    function setqval(arg) {
      store.state.q3val = arg.title
      store.state.q3id = arg.id
      store.state.cTest = store.state.cTest + 1



      const fttimeout = () => {
        UIkit.switcher('#quiz-switch').show(store.state.cTest-1)

      }
      setTimeout(fttimeout, 300)
      const tm = () => {
        UIkit.scroll('', {offset: store.state.quizOffset}).scrollTo('#topQuiz')
      }
      setTimeout(tm, 600)

    }
    const ldspL2 = computed(() => !Number(store.state.q2l2) ? Number(store.state.q2l2) : Number(store.state.q2l2)-60 )
    const ldspL3 = computed(() => !Number(store.state.q2l3) ? Number(store.state.q2l3) : Number(store.state.q2l3)-60 )
    const fasadV = computed(()=> (Number(store.state.q2l1)+ldspL2.value+ldspL3.value)*0.72/100)
    const fasadN = computed(()=> (Number(store.state.q2l1)+ldspL2.value+ldspL3.value)*0.72/100)
    const fasadAll = computed(()=>(fasadN.value+fasadV.value))

    const question = [
      {
        active: false,
        id: 1,
        title: 'Пластик',
        description: store.state.quizMetrFasadPLASTIKPrice,
        img: 'img/quiz/fasad/hpl.webp'
      },
      {
        active: false,
        id: 2,
        title: 'ПВХ пленка',
        description: store.state.quizMetrFasadPVHPrice,
        img: 'img/quiz/fasad/pl.webp'
      },
      {
        active: false,
        id: 3,
        title: 'Крашенные',
        description: store.state.quizMetrFasadKRASKAPrice,
        img: 'img/quiz/fasad/kr.webp'
      },
      {
        active: false,
        id: 4,
        title: 'Комбинированные',
        description: store.state.quizMetrFasadKOMBIPrice,
        img: 'img/quiz/fasad/komb.webp'
      },
      {
        active: false,
        id: 5,
        title: 'Массив дерева',
        description: store.state.quizMetrFasadDEREVOPrice,
        img: 'img/quiz/fasad/msv.webp'
      },
      {
        active: false,
        id: 6,
        title: 'Шпон',
        description: store.state.quizMetrFasadSHPONPrice,
        img: 'img/quiz/fasad/sh.webp'
      }
    ]

    return{
      question, setqval,fasadAll
    }
  }
}
</script>

<style scoped>
.curPoint {
  cursor: pointer!important;
}
.text-subtitle {
  padding: 3px;
  background: rgba(0, 0, 0, 0.7);
}
</style>