<template>
  <h3-title :title="title" v-if="this.$store.state.quizStolVal < 2" />
  <h3-title
    :title="'Точность расчета цены: 87%'"
    v-if="this.$store.state.quizStolVal >= 2"
  />
  <div class="uk-child-width-1-2@s" uk-grid>
    <div v-if="this.$store.state.quizStolVal >= 2">
      <h4 class="text-center" v-if="$q.platform.is.desktop">
        Чтобы получить 100% точный результат запишитесь на бесплатный замер
      </h4>
      <h6 class="text-center uk-h6 q-my-sm" v-if="$q.platform.is.mobile">
        Чтобы получить 100% точный результат запишитесь на бесплатный замер
      </h6>
      <form v-on:submit.prevent>
        <div class="uk-margin">
          <div class="uk-inline uk-width-1-1">
            <span
              v-if="this.$store.state.q10id === ''"
              class="uk-form-icon uk-form-icon-flip"
              ><i class="ph fas fa-phone fa-lg"></i
            ></span>
            <span
              v-if="this.$store.state.q10id === 1"
              class="uk-form-icon uk-form-icon-flip"
              ><i class="ph fas fa-phone fa-lg"></i
            ></span>
            <span
              v-if="this.$store.state.q10id === 2"
              class="uk-form-icon uk-form-icon-flip"
              ><i class="wa fab fa-whatsapp fa-lg"></i
            ></span>
            <span
              v-if="this.$store.state.q10id === 3"
              class="uk-form-icon uk-form-icon-flip"
              ><i class="tg fab fa-telegram-plane fa-lg"></i
            ></span>
            <input
              @input="inp"
              @click="showNumder"
              :class="{
                'uk-form-success': this.inputVal.length === 11,
                'uk-form-danger': this.inputVal.length >= 12,
                'uk-animation-shake': this.inputVal.length >= 12,
              }"
              v-model="inputVal"
              class="uk-input uk-border-rounded"
              type="tel"
              placeholder="Введите телефон"
              style="font-size: 16px !important"
            />
          </div>
        </div>
        <div class="uk-margin uk-text-center">
          <button
            @click="sm"
            v-if="valid"
            :class="{ 'uk-button-danger': this.inputVal.length > 11 }"
            class="uk-button uk-button-primary uk-border-rounded uk-animation-fade uk-button-large uk-animation-slide-top-medium"
          >
            Записаться
          </button>
        </div>
      </form>
    </div>

    <div v-if="this.$store.state.quizStolVal < 2">
      <h4 class="text-center" v-if="$q.platform.is.desktop">
        Выберите, куда вам удобно получить результат?
      </h4>
      <h6 class="text-center uk-h6 q-my-sm" v-if="$q.platform.is.mobile">
        Выберите, куда вам удобно получить результат?
      </h6>
      <div class="uk-text-center uk-margin">
        <a
          v-for="q in question"
          :key="q.id"
          class="uk-icon-button uk-margin-small-right phb"
          :uk-tooltip="q.title"
          @click="setqval(q)"
          :class="{ sss: q.id === this.$store.state.q10id }"
        >
          <i :class="q.description"></i>
        </a>
      </div>
      <form v-on:submit.prevent>
        <div class="uk-margin">
          <div class="uk-inline uk-width-1-1">
            <span
              v-if="this.$store.state.q10id === ''"
              class="uk-form-icon uk-form-icon-flip"
              ><i class="ph fas fa-phone fa-lg"></i
            ></span>
            <span
              v-if="this.$store.state.q10id === 1"
              class="uk-form-icon uk-form-icon-flip"
              ><i class="ph fas fa-phone fa-lg"></i
            ></span>
            <span
              v-if="this.$store.state.q10id === 2"
              class="uk-form-icon uk-form-icon-flip"
              ><i class="wa fab fa-whatsapp fa-lg"></i
            ></span>
            <span
              v-if="this.$store.state.q10id === 3"
              class="uk-form-icon uk-form-icon-flip"
              ><i class="tg fab fa-telegram-plane fa-lg"></i
            ></span>
            <input
              @input="inp"
              @click="showNumder"
              :class="{
                'uk-form-success': this.inputVal.length === 11,
                'uk-form-danger': this.inputVal.length >= 12,
                'uk-animation-shake': this.inputVal.length >= 12,
              }"
              v-model="inputVal"
              class="uk-input uk-border-rounded"
              type="tel"
              placeholder="Введите телефон"
              style="font-size: 16px !important"
            />
          </div>
        </div>
        <div class="uk-margin uk-text-center">
          <button
            @click="sm"
            v-if="valid"
            :class="{ 'uk-button-danger': this.inputVal.length > 11 }"
            class="uk-button uk-button-primary uk-border-rounded uk-animation-fade uk-button-large uk-animation-slide-top-medium"
          >
            Получить
          </button>
        </div>
      </form>
    </div>

    <div>
      <h4 class="uk-text-center q-my-sm">Ваш подарок:</h4>
      <div class="uk-card">
        <div class="uk-card-media-top">
          <div class="uk-flex uk-flex-center">
            <img
              style="
                position: absolute;
                width: 182px;
                z-index: 1;
                right: -1px;
                bottom: -6px;
              "
              src="/img/quiz/gift.webp"
              alt=""
              v-if="$q.platform.is.desktop"
            />
            <img
              style="
                position: absolute;
                width: 182px;
                z-index: 1;
                right: -8px;
                bottom: -6px;
              "
              src="/img/quiz/gift.webp"
              alt=""
              v-if="$q.platform.is.mobile"
            />
            <q-img
              loading="lazy"
              width="550px"
              class="uk-border-rounded"
              v-if="this.$store.state.q9id === 1"
              src="img/quiz/surprise/moika.webp"
            >
              <div class="absolute-top text-center text-subtitle text-lime-12">
                Кухонная мойка
              </div>
            </q-img>
            <q-img
              loading="lazy"
              width="550px"
              class="uk-border-rounded"
              v-if="this.$store.state.q9id === 2"
              src="img/quiz/surprise/sushka.webp"
            >
              <div class="absolute-top text-center text-subtitle text-lime-12">
                Посудосушитель
              </div>
            </q-img>
            <q-img
              loading="lazy"
              width="550px"
              class="uk-border-rounded"
              v-if="this.$store.state.q9id === 3"
              src="img/quiz/surprise/lotok.webp"
            >
              <div class="absolute-top text-center text-subtitle text-lime-12">
                Лоток для приборов
              </div>
            </q-img>
          </div>
        </div>
        <div class="uk-card-body uk-padding-small">
          <p class="uk-text-center" v-if="this.$store.state.q9id === 1"></p>
          <p class="uk-text-center" v-if="this.$store.state.q9id === 2"></p>
          <p class="uk-text-center" v-if="this.$store.state.q9id === 3"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import H3Title from "../../../UiComponents/h3-title";
import { computed, onMounted, reactive, ref } from "vue";
import axios from "axios";
import { useQuasar } from "quasar";

export default {
  name: "finishKitchen",
  components: { H3Title },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    const $q = useQuasar();
    const cookies = $q.cookies;
    const store = useStore();

    const showPrice = computed(() => {
      if (store.state.quizStolVal < 2) {
        return false;
      }
      if (store.state.quizStolVal >= 2) {
        return true;
      }
    });

    const showNumder = () => {
      if (store.state.StConversionPhone && inputVal.value.length < 11) {
        $q.notify({
          progress: true,
          message: Number(11 - inputVal.value.length === 1)
            ? "Мы опредилили Ваш номер телефона, введите последнюю цифру"
            : "Мы опредилили Ваш номер телефона, введите последние " +
              Number(11 - inputVal.value.length) +
              " цифры",
          color: "primary",
          position: "bottom",
        });
      }
    };

    function sm(arg) {
      if (inputVal.value.length >= 10 && inputVal.value.length <= 11) {
        store.state.qphone = inputVal.value;
        cookies.set("leadConversionPhone", store.state.qphone, {
          expires: "30d",
        });
        cookies.set("leadConversion", "true", { expires: "30d" });
        showDone();

        axios
          .post("https://api.sokolmeb.ru", {
            quiz: true,
            typeKi: store.state.q1val,
            L1Ki: store.state.q2l1,
            L2Ki: store.state.q2l2,
            L3Ki: store.state.q2l3,
            idKi: store.state.q1id,
            fasadKi: store.state.q3val,
            stolKi: store.state.q4val,
            holodKi: store.state.q5val,
            stpanKi: store.state.q6val,
            furKi: store.state.q7val,
            ruchKi: store.state.q8val,
            surpKi: store.state.q9val,
            contactKi: store.state.q10val,
            phoneKi: store.state.qphone,
            priceKi: store.state.qprice,
            showPriceKi: showPrice.value,
          })
          .then(
            (store.state.quizDone = true),
            ym(95205055, "reachGoal", "allConvertion"),
            ym(95205055, "reachGoal", "quizKitchenDone"),
            (store.state.StLeadConversion = true),
            function (response) {}
          )
          .catch(function (error) {
            console.log(error);
          });
      }
    }

    function inp() {
      if (inputVal.value.length >= 13) {
        inputVal.value = "";
      }
      if (inputVal.value.length === 11) {
        this.valid = true;
      }
      inputVal.value = inputVal.value.toString().replace(/[^\d]/g, "");
    }

    function setqval(arg) {
      store.state.q10val = arg.title;
      store.state.q10id = arg.id;
      //  this.$store.state.cTest = this.$store.state.cTest + 1

      const fttimeout = () => {
        UIkit.switcher("#quiz-switch").show(store.state.cTest - 1);
      };
      //  setTimeout(fttimeout, 300)
      const tm = () => {
        UIkit.scroll("", { offset: store.state.quizOffset }).scrollTo(
          "#topQuiz"
        );
      };
      // setTimeout(tm, 600)
    }

    const question = [
      {
        active: false,
        id: 1,
        title: "Телефон",
        description: "ph fas fa-phone fa-lg",
        img: "img/quiz/surprise/moika.webp",
      },
      {
        active: false,
        id: 2,
        title: "WhatsApp",
        description: "wa fab fa-whatsapp fa-lg",
        img: "img/quiz/surprise/sushka.webp",
      },
      {
        active: false,
        id: 3,
        title: "Telegram",
        description: "tg fab fa-telegram-plane fa-lg",
        img: "img/quiz/surprise/lotok.webp",
      },
    ];

    function showDone() {
      const notif = $q.notify({
        group: false, // required to be updatable
        timeout: 0, // we want to be in control when it gets dismissed
        spinner: true,
        message: "Отправка данных",
        caption: "0%",
        color: "primary",
      });

      // we simulate some progress here...
      let percentage = 0;
      const interval = setInterval(() => {
        percentage = Math.min(100, percentage + Math.floor(Math.random() * 22));

        // we update the dialog
        notif({
          caption: `${percentage}%`,
        });

        // if we are done...
        if (percentage === 100) {
          notif({
            icon: "done", // we add an icon
            spinner: false, // we reset the spinner setting so the icon can be displayed
            message: "Готово, ожидайте результат!",
            color: "primary",
            timeout: 2500, // we will timeout it in 2.5s
          });
          clearInterval(interval);
        }
      }, 200);
    }

    const inputVal = ref(
      store.state.StConversionPhone
        ? store.state.StConversionPhone.toString().slice(0, -4)
        : ""
    );
    const valid = ref(false);
    const sendDone = ref(false);

    return {
      inputVal,
      valid,
      sendDone,
      setqval,
      inp,
      sm,
      question,
      showNumder,
      showDone,
      showPrice,
    };
  },
};
</script>

<style scoped>
.wa {
  color: #43d854;
}
.tg {
  color: #0088cc;
}
.ph {
  color: #ee073b;
}
.sss {
  color: #0e6dcd;
  border: #0e6dcd 1px solid;
}
.text-subtitle {
  padding: 3px;
  background: rgba(0, 0, 0, 0.7);
}
</style>
