<template>

  <modal-zamer :state="sm" />
  <div class="uk-container uk-visible@m" v-show="cdSum > 0">
    <div class="img-discount">
      <img src="/img/kitchen/girls_PNG6462.png" alt="" />
    </div>

    <div
      class="uk-box-shadow-xlarge uk-padding bg-white uk-border-rounded uk-margin-large-bottom"
    >
      <h2
        class="uk-heading-medium uk-text-center uk-margin-remove-bottom text-teal-14"
        uk-scrollspy="cls: uk-animation-slide-left-small; delay: 200"
      >
	      Скидка на новую мебель
      </h2>
      <div
        uk-grid
        class="uk-grid-match uk-child-width-1-2@m uk-margin-medium-bottom uk-margin-medium-top"
      >
        <div>
          <div
            class="uk-heading-2xlarge uk-text-center title-3d text-teal-14"
            uk-scrollspy="cls: uk-animation-slide-left; delay: 300"
          >
            <b>{{ this.$store.state.StDiscountCount }}%</b>
          </div>
        </div>
        <div class="" uk-scrollspy="cls: uk-animation-slide-right; delay: 400">
          <span class="text-h5 uk-text-center">До конца акции осталось:</span>
          <div
            class="uk-grid-small uk-child-width-auto uk-text-center uk-margin-small-top"
            uk-grid
            :uk-countdown="getDate"
          >
            <div>
              <div class="uk-countdown-number uk-countdown-days"></div>
              <div
                class="uk-countdown-label uk-margin-small uk-text-center uk-visible@s"
              >
                {{ cdDay }}
              </div>
            </div>
            <div class="uk-countdown-separator uk-align-center">:</div>
            <div>
              <div class="uk-countdown-number uk-countdown-hours"></div>
              <div
                class="uk-countdown-label uk-margin-small uk-text-center uk-visible@s"
              >
                {{ cdHour }}
              </div>
            </div>
            <div class="uk-countdown-separator uk-align-center">:</div>
            <div>
              <div class="uk-countdown-number uk-countdown-minutes"></div>
              <div
                class="uk-countdown-label uk-margin-small uk-text-center uk-visible@s"
              >
                {{ cdMin }}
              </div>
            </div>
            <div class="uk-countdown-separator uk-align-center">:</div>
            <div>
              <div
                class="uk-countdown-number uk-countdown-seconds text-teal-14"
              ></div>
              <div
                class="uk-countdown-label uk-margin-small uk-text-center uk-visible@s"
              >
                {{ cdSec }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="uk-text-center">
        <div class="uk-button-group">
          <q-btn
            class="text-black"
            label="Консультация дизайнера"
            @click="sm = !sm"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="uk-container uk-hidden@m" v-show="cdSum > 0">
    <div class="">
      <img class="mobile-img" src="/img/kitchen/girls_PNG6462.png" alt="" />
    </div>

    <div
      class="uk-box-shadow-xlarge uk-padding bg-white uk-border-rounded uk-margin-medium-bottom"
    >
      <h2
        class="text-h4 uk-text-center text-teal-14"
        uk-scrollspy="cls: uk-animation-slide-left-small; delay: 200"
      >
        Скидка на новую мебель
      </h2>
      <div
        uk-grid
        class="uk-grid-match uk-child-width-1-2@m uk-margin-medium-bottom uk-margin-large-top"
      >
        <div>
          <div
            class="uk-heading-2xlarge uk-text-center text-teal-14 title-3d"
            uk-scrollspy="cls: uk-animation-slide-left-small; delay: 300"
          >
            {{ this.$store.state.StDiscountCount }}%
          </div>
        </div>
        <div
          class=""
          uk-scrollspy="cls: uk-animation-slide-left-small; delay: 400"
        >
          <span
            class="uk-text-center uk-margin-small-bottom"
            style="font-size: 1.3rem"
            >До конца акции осталось:</span
          >
          <div
            class="uk-grid-small uk-child-width-expand uk-margin-remove-top"
            uk-grid
            :uk-countdown="getDate"
          >
            <div>
              <div
                class="uk-countdown-number uk-countdown-days uk-text-center"
              ></div>
              <div class="uk-countdown-label uk-margin-small uk-text-center">
                {{ cdDay }}
              </div>
            </div>

            <div>
              <div
                class="uk-countdown-number uk-countdown-hours uk-text-center"
              ></div>
              <div class="uk-countdown-label uk-margin-small uk-text-center">
                {{ cdHour }}
              </div>
            </div>

            <div>
              <div
                class="uk-countdown-number uk-countdown-minutes uk-text-center"
              ></div>
              <div class="uk-countdown-label uk-margin-small uk-text-center">
                {{ cdMin }}
              </div>
            </div>

            <div>
              <div
                class="uk-countdown-number uk-countdown-seconds uk-text-center text-teal-14"
              ></div>
              <div class="uk-countdown-label uk-margin-small uk-text-center">
                {{ cdSec }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="uk-text-center">
        <q-btn
          class="text-black"
          label="Консультация дизайнера"
          @click="sm = !sm"
        />
      </div>
    </div>
  </div>

  <!--  <div class="testClass">01- {{cdSum}} </div>-->
</template>

<script>
import ModalZamer from "../../UiComponents/ModalZamer";
import { onMounted, onUpdated, ref } from "vue";
import axios from "axios";
import {data} from "uikit/src/js/util";


export default {
  name: "DvmDiscount",
  components: { ModalZamer },
  props: {
    title: {
      type: String,
    },
  },
  setup() {
    const sm = ref(false);
    const cdSec = ref();
    const cdMin = ref();
    const cdHour = ref();
    const cdDay = ref();


     axios.get('https://api.sokolmeb.ru/lic')
        .then(data => {
          getDate.value = data.data.date
        })

    const getDate = ref("")


    const cdSum = ref();
    //+Number(cdMin.value)+Number(cdHour.value)+Number(cdDay.value)
    onMounted(() => {
      function initTimer() {
        setInterval(function () {
          let declOfNum = function (number, titles) {
            let cases = [2, 0, 1, 1, 1, 2];
            return titles[
              number % 100 > 4 && number % 100 < 20
                ? 2
                : cases[number % 10 < 5 ? number % 10 : 5]
            ];
          };
          const day = document.querySelector(".uk-countdown-days").textContent;
          const hour = document.querySelector(
            ".uk-countdown-hours"
          ).textContent;
          const min = document.querySelector(
            ".uk-countdown-minutes"
          ).textContent;
          const sec = document.querySelector(
            ".uk-countdown-seconds"
          ).textContent;

          cdDay.value = declOfNum(day, ["День", "Дня", "Дней"]);
          cdHour.value = declOfNum(hour, ["Час", "Часа", "Часов"]);
          cdMin.value = declOfNum(min, ["Минута", "Минуты", "Минут"]);
          cdSec.value = declOfNum(sec, ["Секунда", "Секунды", "Секунд"]);

          cdSum.value = Number(day) + Number(hour) + Number(min) + Number(sec);
        }, 30);
      }
      initTimer();
    });

    return {
      sm,
      cdSec,
      cdMin,
      cdHour,
      cdDay,
      cdSum,
      getDate
    };
  },
};
</script>

<style scoped lang="scss">
.q-btn {
	color: #222;
	background: rgb(246,195,127);
	background: linear-gradient(137deg, rgba(246,195,127,1) 7%, rgba(186,146,90,1) 24%, rgba(151,111,64,1) 42%, rgba(246,195,127,1) 75%, rgba(246,195,127,1) 85%);
	//display: inline-block;
	//text-transform: uppercase;
	//
	//box-shadow: 0 2px 5px 0 rgba(3,6,26,0.15);
	//transition: .5s all ease-in-out;
	&:hover {
		color: #fff;
		outline: 2px solid rgb(93, 74, 50);
	}
}

.title-3d {
  text-shadow: 0px 0px 0 rgb(-64, -126, 0), 1px 1px 0 rgb(-127, -189, 0),
    2px 2px 0 rgb(-191, -253, 0), 3px 3px 0 rgb(-255, -317, 0),
    4px 4px 0 rgb(-319, -381, 0), 5px 5px 4px rgba(0, 0, 0, 1),
    5px 5px 1px rgba(0, 0, 0, 0.5), 0px 0px 4px rgba(0, 0, 0, 0.2);
}
.img-discount {
  position: relative;
  top: 62px;
  z-index: 2;
}
.mobile-img {
  height: 70%;
  width: 70%;
  position: relative;
  top: 42px;
  z-index: 2;
}

.uk-countdown-separator {
  font-size: 1rem;
}

@media (min-width: 960px) {
  .uk-countdown-number {
    font-size: 3.5rem;
  }
}

@media (min-width: 1120px) {
  .uk-countdown-number {
    font-size: 4.5rem;
  }
}
</style>
