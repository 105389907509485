<template>
  <section style="position: relative" class="flex justify-center">
    <div style="max-width: 1400px">
      <div v-if="$q.platform.is.desktop">
        <h2
          class="text-center text-white uk-margin-remove-top uk-margin-small-bottom"
        >
          Счастливые и довольные клиенты
        </h2>
        <h5 class="tcolor text-center text-teal-14 uk-margin-remove-top">
          Мы дарим исключительно положительные эмоции
        </h5>
      </div>

      <div v-if="$q.platform.is.mobile">
        <h2
          class="text-center text-white text-h4 uk-margin-remove-top uk-margin-small-bottom"
        >
          Счастливые и довольные клиенты
        </h2>
        <h5
          class="tcolor text-center text-teal-14 text-h6 uk-margin-remove-top"
        >
          Мы дарим исключительно положительные эмоции
        </h5>
      </div>

      <q-carousel
        v-model="slide"
        transition-prev="slide-right"
        transition-next="slide-left"
        swipeable
        autoplay
        infinite
        animated
        dark
        control-color="teal-14"
        navigation
        padding
        height="450px"
        class="q-pa-sm"
      >
        <!--    <q-carousel-slide :name="1" class="column no-wrap uk-visible@m" v-if="$q.platform.is.mobile">-->
        <!--      <div class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap">-->
        <!--        <q-img :ratio="4/3" class="rounded-borders col-6 full-height" src="img/clt/1.jpg" />-->
        <!--      </div>-->
        <!--    </q-carousel-slide>-->

        <q-carousel-slide
          class="rounded-borders"
          :name="1"
          img-src="img/clt/1.jpg"
          v-if="$q.platform.is.mobile"
        />
        <q-carousel-slide
          class="rounded-borders"
          :name="2"
          img-src="img/clt/2.jpg"
          v-if="$q.platform.is.mobile"
        />
        <q-carousel-slide
          class="rounded-borders"
          :name="3"
          img-src="img/clt/3.jpg"
          v-if="$q.platform.is.mobile"
        />
        <q-carousel-slide
          class="rounded-borders"
          :name="4"
          img-src="img/clt/4.jpg"
          v-if="$q.platform.is.mobile"
        />
        <q-carousel-slide
          class="rounded-borders"
          :name="5"
          img-src="img/clt/5.jpg"
          v-if="$q.platform.is.mobile"
        />
        <q-carousel-slide
          class="rounded-borders"
          :name="6"
          img-src="img/clt/6.jpg"
          v-if="$q.platform.is.mobile"
        />
        <q-carousel-slide
          class="rounded-borders"
          :name="7"
          img-src="img/clt/7.jpg"
          v-if="$q.platform.is.mobile"
        />
        <q-carousel-slide
          class="rounded-borders"
          :name="8"
          img-src="img/clt/8.jpg"
          v-if="$q.platform.is.mobile"
        />
        <q-carousel-slide
          class="rounded-borders"
          :name="9"
          img-src="img/clt/9.jpg"
          v-if="$q.platform.is.mobile"
        />
        <q-carousel-slide
          class="rounded-borders"
          :name="10"
          img-src="img/clt/10.jpg"
          v-if="$q.platform.is.mobile"
        />

        <q-carousel-slide
          :name="1"
          class="column no-wrap uk-visible@m"
          v-if="$q.platform.is.desktop"
        >
          <div
            class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap"
          >
            <q-img
              :ratio="4 / 3"
              class="rounded-borders col-6 full-height"
              src="img/clt/1.jpg"
            />
            <q-img
              :ratio="4 / 3"
              class="rounded-borders col-6 full-height"
              src="img/clt/2.jpg"
            />
          </div>
        </q-carousel-slide>
        <q-carousel-slide
          :name="2"
          class="column no-wrap uk-visible@m"
          v-if="$q.platform.is.desktop"
        >
          <div
            class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap"
          >
            <q-img
              :ratio="4 / 3"
              class="rounded-borders col-6 full-height"
              src="img/clt/3.jpg"
            />
            <q-img
              :ratio="4 / 3"
              class="rounded-borders col-6 full-height"
              src="img/clt/4.jpg"
            />
          </div>
        </q-carousel-slide>
        <q-carousel-slide
          :name="3"
          class="column no-wrap uk-visible@m"
          v-if="$q.platform.is.desktop"
        >
          <div
            class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap"
          >
            <q-img
              :ratio="4 / 3"
              class="rounded-borders col-6 full-height"
              src="img/clt/5.jpg"
            />
            <q-img
              :ratio="4 / 3"
              class="rounded-borders col-6 full-height"
              src="img/clt/6.jpg"
            />
          </div>
        </q-carousel-slide>
        <q-carousel-slide
          :name="4"
          class="column no-wrap uk-visible@m"
          v-if="$q.platform.is.desktop"
        >
          <div
            class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap"
          >
            <q-img
              :ratio="4 / 3"
              class="rounded-borders col-6 full-height"
              src="img/clt/7.jpg"
            />
            <q-img
              :ratio="4 / 3"
              class="rounded-borders col-6 full-height"
              src="img/clt/8.jpg"
            />
          </div>
        </q-carousel-slide>
        <q-carousel-slide
          :name="5"
          class="column no-wrap uk-visible@m"
          v-if="$q.platform.is.desktop"
        >
          <div
            class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap"
          >
            <q-img
              :ratio="4 / 3"
              class="rounded-borders col-6 full-height"
              src="img/clt/9.jpg"
            />
            <q-img
              :ratio="4 / 3"
              class="rounded-borders col-6 full-height"
              src="img/clt/10.jpg"
            />
          </div>
        </q-carousel-slide>
      </q-carousel>
      <div style="height: 20px" v-if="$q.platform.is.mobile"></div>
      <div class="topSplashBottom"></div>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";
export default {
  name: "DvmCorusel",
  setup() {
    return {
      slide: ref(1),
    };
  },
};
</script>

<style scoped>
.topSplashBottom {
  height: 4px;
  width: 100%;
  position: absolute;
  transform: scale(1, -1);
  content: "";
  z-index: 100;
  background: url(/img/test/how-we-work-people-plash1.png) repeat-x;
  bottom: -4px;
  left: 0;
}
</style>
