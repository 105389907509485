<template >
	<div class="uk-flex uk-flex-center">
	<video class="uk-hidden@s" src="video/alexey.mp4" width="360" controls muted uk-video="autoplay: inview"></video>
	</div>
</template>

<script setup>

</script>

<style scoped lang="scss">

</style>