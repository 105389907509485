<template>
  <q-parallax src="/img/parallax.png" :height="600" :speed="1">
    <div class="uk-overlay uk-overlay-primary uk-border-rounded uk-text-center">
      <h2
        class="text-white title-3d uk-margin-small-bottom uk-margin-remove-top"
        v-if="$q.platform.is.desktop"
      >
        <span class="text-teal-14">Кухни под заказ</span> для людей, которые не
        торопятся
      </h2>
      <h3 class="text-white title-3d uk-margin-remove-top">
        Которым не нужно быстро –
        <span class="text-teal-14">нужно качественно</span>
      </h3>
    </div>
  </q-parallax>
</template>

<script>
export default {
  name: "dvmParallax",
};
</script>

<style scoped>
.title-3d {
  text-shadow: 0px 0px 0 rgb(-64, -126, 0), 1px 1px 0 rgb(-127, -189, 0),
    2px 2px 0 rgb(-191, -253, 0), 3px 3px 0 rgb(-255, -317, 0),
    4px 4px 0 rgb(-319, -381, 0), 5px 5px 4px rgba(0, 0, 0, 1),
    5px 5px 1px rgba(0, 0, 0, 0.5), 0px 0px 4px rgba(0, 0, 0, 0.2);
}
</style>
