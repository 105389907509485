<template>
  <button

      class="uk-button uk-button-primary uk-border-rounded uk-animation-fade uk-button-large uk-animation-slide-top-medium"
      v-on:click="nextPage"
  >
    <slot></slot>
  </button>
</template>

<script>
import { useStore } from 'vuex'
export default {
  name: "quizButton",
  setup() {
    const store = useStore()
    function   nextPage() {
      store.state.cTest = store.state.cTest + 1
      store.state.dngr = false
      const time = () => {
        UIkit.switcher('#quiz-switch').show(store.state.cTest - 1)


      }
      setTimeout(time, 300)
      const tm = () => {
        UIkit.scroll('', {offset: store.state.quizOffset}).scrollTo('#topQuiz')
      }
      setTimeout(tm, 600)
    }
    return {
      nextPage
    }
  }
}
</script>

<style scoped>

</style>