<template>
  <header>
    <div
      class="uk-position-relative uk-visible-toggle uk-light uk-visible@m"
      tabindex="-1"
      uk-slideshow="animation: fade; autoplay: true; pause-on-hover: false; autoplay-interval: 5000; min-height: 600; max-height: 600"
    >
      <ul class="uk-slideshow-items">
        <li v-for="index in 8" :key="index">
          <img
            :src="'/crop-img/kitchen/header/' + index + '-1280.webp'"
            alt="Мебель на заказ в Комсомольске-на-Амуре"
            class="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-left"
            uk-cover
          />

          <teleport to="head">
            <link
              rel="preload"
              as="image"
              type="image/webp"
              :href="'/crop-img/kitchen/header/' + index + '-1280.webp'"
            />
          </teleport>

          <div class="uk-overlay uk-position-cover tessst">
            <div class="uk-padding-small">
              <div uk-grid class="uk-child-width-expand@s">
                <div style="max-width: 850px">




                  <h1 class="q-mb-lg text-h2 q-mt-none text-white">
                    Изготовим и установим <span class="text-teal-14">эксклюзивную </span>
                    <span class="text-teal-14">мебель </span>по индивидуальным проектам <br>
	                  в
	                  <span class="text-teal-14">Комсомольске-на-Амуре
                    </span>
                  </h1>

                  <!--                    <q-btn-->
                  <!--                      color="teal-9"-->
                  <!--                      icon="calculate"-->
                  <!--                      type="a"-->
                  <!--                      href="#calc"-->
                  <!--                      uk-scroll="offset:130"-->
                  <!--                      size="m"-->
                  <!--                      label="Калькулятор стоимости"-->
                  <!--                    />-->
                  <div
                    class="uk-text-center "
                  >
<!--	                  <conv-button @click="sm = !sm" v-model="sm">Оставить заявку</conv-button>-->
                    <div class="q-mb-lg">
                      <q-btn color="red" @click="scroll('#calc','130')" label="Рассчитать стоимость" size="lg"/>
                      <q-btn class="q-ml-lg" color="white" outline label="Оставить заявку" size="lg" @click="sm = !sm" v-model="sm"/>
                    </div>
<!--                    <q-btn-->
<!--                      color="teal-14 text-black"-->
<!--                      icon-right="straighten"-->
<!--                      label="Записаться на замер"-->
<!--                      size="m"-->
<!--                      @click="sm = !sm"-->
<!--                      v-model="sm"-->
<!--                    />-->
                  </div>

                  <div
                    uk-grid
                    class="uk-child-width-expand uk-margin-small-top text-white"
                    style="font-size: 1.3em"
                  >
                    <div class="uk-card uk-flex uk-flex-middle">
                      <q-icon
                        class="text-teal-14"
                        style="font-size: 4em"
                        name="done"
                      />
                      <p>
                        <span class="text-teal-14">100%</span> соответствие
                        проекту
                      </p>
                    </div>

                    <div class="uk-card uk-flex uk-flex-middle">
                      <q-icon
                        class="text-teal-14"
                        style="font-size: 4em"
                        name="done"
                      />
                      <p>
                        <span class="text-teal-14">Реализация</span> проектов
                        любой сложности
                      </p>
                    </div>

                    <div class="uk-card uk-flex uk-flex-middle">
                      <q-icon
                        class="text-teal-14"
                        style="font-size: 4em"
                        name="done"
                      />
                      <p>
                        <span class="text-teal-14">Гарантия</span> на всю мебель
                      </p>
                    </div>

                    <!--                    <div class="uk-card">-->
                    <!--                      Реализация проектов любой сложности-->
                    <!--                    </div>-->
                    <!--                    <div class="uk-card">Гарантия на всю мебель</div>-->
                  </div>
                </div>
                <div
                  class="uk-flex uk-flex-bottom uk-flex-center uk-position-relative"
                >

                </div>
              </div>
            </div>
          </div>


          <div
            class="uk-position-top-right uk-position-small uk-hidden"
            v-if="$q.platform.is.desktop"
          >
            <div class="block-button">
              <a @click="clickPhone" class="uk-link" href="tel:+79996600369">
                <span class="text-h4 text-cyan-13 title-3d"
                  >8 (969)-305-0055<i
                    class="fas fa-phone padding-dot-phone q-ml-sm text-cyan-13"
                  ></i
                ></span>
              </a>
            </div>
          </div>

          <div
            class="uk-position-top-center uk-position-medium"
            v-if="$q.platform.is.desktop"
          >

          </div>
        </li>
      </ul>
    </div>




	  <div class="uk-container uk-container-large text-white uk-padding-small uk-hidden@m">
		  <div class="uk-flex uk-flex-middle uk-flex-between">
			  <div class="uk-flex uk-flex-middle">
				  <div class="uk-position-relative">
					  <a href="/" class="uk-navbar-item uk-logo uk-position-relative "
					  ><img
						  class="text-teal-14"
						  src="img/sokol.svg"
						  alt=""
						  style="width: 100px; height: 100px;"
						  uk-svg
					  />
						  <div style="bottom: -5px; font-size: 0.5em;" class="uk-position-bottom-center text-teal-14">СОКОЛ</div>

					  </a>
				  </div>
				  <div style="width: 200px">
					  Производим и устанавливаем мебель в
					  <span class="text-teal-14">Комсомольске-на-Амуре</span> и области с 2013г.
				  </div>
			  </div>

		  </div>
	  </div>
	  <div
			  class="uk-position-relative uk-visible-toggle uk-light uk-hidden@m"
			  tabindex="-1"
			  uk-slideshow="animation: fade; autoplay: true; pause-on-hover: false; autoplay-interval: 5000; min-height: 450; max-height: 500"
	  >
		  <ul class="uk-slideshow-items">
			  <li v-for="index in 8" :key="index">
				  <img
						  :src="'/crop-img/kitchen/header/' + index + '-1280.webp'"
						  alt="Мебель на заказ в Комсомольске-на-Амуре"
						  class="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-left"
						  uk-cover
				  />



				  <div class="uk-overlay uk-position-cover tessst mob_padding">
					  <div class="uk-padding-small">
						  <div uk-grid class="uk-child-width-expand@s">
							  <div style="max-width: 850px">




								  <h1 class="q-mb-lg text-h2 q-mt-none text-white mob">
									  Изготовим и установим <span class="text-teal-14">эксклюзивную </span>
									  <span class="text-teal-14">мебель </span>по индивидуальным проектам <br>
									  в
									  Комсомольске-на-Амуре
									  <span class="text-white">и области</span>
								  </h1>

								  <!--                    <q-btn-->
								  <!--                      color="teal-9"-->
								  <!--                      icon="calculate"-->
								  <!--                      type="a"-->
								  <!--                      href="#calc"-->
								  <!--                      uk-scroll="offset:130"-->
								  <!--                      size="m"-->
								  <!--                      label="Калькулятор стоимости"-->
								  <!--                    />-->
								  <div
										  class="uk-text-center mob_btn q-my-lg column"
								  >
<!--									  <conv-button @click="sm = !sm" v-model="sm" class="ttt">Оставить заявку</conv-button>-->

                      <q-btn color="red" @click="scroll('#calc','130')" label="Рассчитать стоимость" size="lg"/>
                      <q-btn class="q-mt-lg" color="white" outline label="Оставить заявку" size="lg" @click="sm = !sm" v-model="sm"/>

								  </div>

								  <div
										  uk-grid
										  class="uk-child-width-expand uk-margin-small-top text-white vis"
										  style="font-size: 1.3em"
								  >
									  <div class="uk-card uk-flex uk-flex-middle">
										  <q-icon
												  class="text-teal-14"
												  style="font-size: 4em"
												  name="done"
										  />
										  <p>
											  <span class="text-teal-14">100%</span> соответствие
											  проекту
										  </p>
									  </div>

									  <div class="uk-card uk-flex uk-flex-middle">
										  <q-icon
												  class="text-teal-14"
												  style="font-size: 4em"
												  name="done"
										  />
										  <p>
											  <span class="text-teal-14">Реализация</span> проектов
											  любой сложности
										  </p>
									  </div>

									  <div class="uk-card uk-flex uk-flex-middle">
										  <q-icon
												  class="text-teal-14"
												  style="font-size: 4em"
												  name="done"
										  />
										  <p>
											  <span class="text-teal-14">Гарантия</span> на всю мебель
										  </p>
									  </div>

									  <!--                    <div class="uk-card">-->
									  <!--                      Реализация проектов любой сложности-->
									  <!--                    </div>-->
									  <!--                    <div class="uk-card">Гарантия на всю мебель</div>-->
								  </div>
							  </div>

						  </div>
					  </div>
				  </div>


				  <div
						  class="uk-position-top-right uk-position-small uk-hidden"
						  v-if="$q.platform.is.desktop"
				  >
					  <div class="block-button">
						  <a @click="clickPhone" class="uk-link" href="tel:+79996600369">
                <span class="text-h4 text-cyan-13 title-3d"
                >8 (969)-305-0055<i
		                class="fas fa-phone padding-dot-phone q-ml-sm text-cyan-13"
                ></i
                ></span>
						  </a>
					  </div>
				  </div>

				  <div
						  class="uk-position-top-center uk-position-medium"
						  v-if="$q.platform.is.desktop"
				  >
					  <!--            <a href="/" class="uk-navbar-item uk-logo">-->
					  <!--              <img-->
					  <!--                src="img/logo.svg"-->
					  <!--                alt="Кухни на заказ в Milmar | 89996600369"-->
					  <!--                class="logo"-->
					  <!--                srcset=""-->
					  <!--              />-->
					  <!--            </a>-->
				  </div>
			  </li>
		  </ul>
	  </div>























    <modal-zamer
      v-model="sm"
      :state="sm"
      btn-title="Записаться на замер"
      content=""
    />
    <modal10-visits v-model="tenv" :state="tenv"/>



  </header>
</template>

<script>
import {onMounted, ref} from "vue";
import ModalZamer from "../../UiComponents/ModalZamer";
import Modal10Visits from "../../UiComponents/Modal10Visits";
import ConvButton from "../../UiComponents/convButton";
import {useQuasar} from "quasar";
export default {
  name: "dvmHeader",
  components: {ConvButton, Modal10Visits, ModalZamer },
  setup() {


    const sm = ref(false);
    const tenv = ref(false);
    const clickPhone = () => {
      ym(93834777, "reachGoal", "conversionPhone");
    };
    const $q=useQuasar()
    const scroll = (id, offset) => {
      if ($q.platform.is.mobile) {
        offset=offset-40
      }

      UIkit.scroll('',{offset: offset}).scrollTo(id)
    }
    return {
      sm,
      clickPhone,
      tenv,
      scroll,
    };
  },
};
</script>

<style scoped>







.cover {
  background-color: rgba(0, 0, 0, 0.5);
  outline: 2px solid #00e5ff;
  padding: 50px;
}

.title-3d {
  text-shadow: 0px 0px 0 rgb(-64, -126, 0), 1px 1px 0 rgb(-127, -189, 0),
    2px 2px 0 rgb(-191, -253, 0), 3px 3px 0 rgb(-255, -317, 0),
    4px 4px 0 rgb(-319, -381, 0), 5px 5px 4px rgba(0, 0, 0, 1),
    5px 5px 1px rgba(0, 0, 0, 0.5), 0px 0px 4px rgba(0, 0, 0, 0.2);
}
.my-shadow {
  text-shadow: 0px 0px 6px #ffffff, 0px 0px 6px #ffffff, 0px 0px 6px #ffffff,
    0px 0px 6px #ffffff, 0px 0px 6px #ffffff, 0px 0px 6px #ffffff,
    0px 0px 6px #ffffff, 3px 3px 6px #ffffff, 3px 3px 6px #ffffff,
    3px 3px 6px #ffffff, 3px 3px 6px #ffffff, 3px 3px 6px #ffffff,
    3px 3px 6px #ffffff, 3px 3px 6px #ffffff, 3px 0px 6px #ffffff,
    3px 0px 6px #ffffff, 3px 0px 6px #ffffff, 3px 0px 6px #ffffff,
    3px 0px 6px #ffffff, 3px 0px 6px #ffffff, 3px 0px 6px #ffffff,
    -3px 0px 6px #ffffff, -3px 0px 6px #ffffff, -3px 0px 6px #ffffff,
    -3px 0px 6px #ffffff, -3px 0px 6px #ffffff, -3px 0px 6px #ffffff,
    -3px 0px 6px #ffffff, -3px 0px 6px #ffffff, -3px 0px 6px #ffffff,
    -3px 0px 6px #ffffff, -3px 0px 6px #ffffff, -3px 0px 6px #ffffff,
    -3px 0px 6px #ffffff, -3px -3px 6px #ffffff, -3px -3px 6px #ffffff,
    -3px -3px 6px #ffffff, -3px -3px 6px #ffffff, -3px -3px 6px #ffffff,
    -3px -3px 6px #ffffff, -3px -3px 6px #ffffff, -3px -3px 6px #ffffff,
    -3px -3px 6px #ffffff, -3px -3px 6px #ffffff, -3px -3px 6px #ffffff,
    -3px -3px 6px #ffffff, -3px -3px 6px #ffffff, -3px -3px 6px #ffffff,
    -3px -3px 6px #ffffff, -3px -3px 6px #ffffff, -3px -3px 6px #ffffff,
    -3px -3px 6px #ffffff, -3px -3px 6px #ffffff, -3px -3px 6px #ffffff,
    -3px -3px 6px #ffffff, -3px -3px 6px #ffffff, -3px -3px 6px #ffffff;
}

.caption {
  text-align: center;

  padding: 12px;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
}

.tessst {
  background: rgb(34, 34, 34);
  background: linear-gradient(
    90deg,
    rgba(34, 34, 34, 1) 0%,
    rgba(34, 34, 34, 0) 100%
  );
}

.blink::after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: 6px solid #00bfa5;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
}

.blink:hover {
  transform: scale(1.2);
  transition: 1s ease;
}

@keyframes ring {
  0% {
    width: 60px;
    height: 60px;
    opacity: 1;
  }
  100% {
    width: 150px;
    height: 150px;
    opacity: 0;
  }
}

a {
  text-decoration: none;
}

.block-button:hover {
  animation: rotate-header 1s infinite;
}
@keyframes rotate-header {
  0% {
    transform: rotate(0.01turn);
  }
  50% {
    transform: rotate(-0.01turn);
  }
  100% {
    transform: rotate(0.01turn);
  }
}
.logo {
  width: 250px;
  height: 250px;
}
.alexey {
	height: 800px;
}


@media screen and (max-width: 1490px) {
	h1  {
		font-size: 2em;
	}
	p{
		font-size: 0.8em;
	}
	.alexey{
		height: 600px;
	}
  .cover > h1,
  h2,
  span > b span {
    font-size: 2em;
  }
  .logo {
    width: 150px;
    height: 150px;
  }
}

@media screen and (max-width: 1090px) {
  .cover > h1,
  h2,
  span > b span {
    font-size: 2em;
  }
  .logo {
    width: 150px;
    height: 150px;
  }
}
@media screen and (max-width: 850px){
.mob {
	line-height: 2rem;
	font-size: 1.8em;
}
.ttt{
	padding: 0px 18px;
	font-size: 1em;
}

.mob_padding {
  padding-left: 5px;
  padding-right: 5px;
}

}

@media screen and (max-width: 530px){
	.vis{
		display: none;
	}

}
</style>
