<template>
  <q-page-scroller position="bottom-right" :scroll-offset="150" :offset="[18, 18]">
    <q-btn fab icon="keyboard_arrow_up" color="teal-14" />
  </q-page-scroller>
</template>

<script>
export default {
  name: "scrollTop"
}
</script>

<style scoped>

</style>