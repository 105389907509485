<template>
  <div class="uk-section uk-section-secondary uk-padding-small">
    <div class="uk-child-width-expand@m uk-child-width-1-1@s" uk-grid>
      <div>
        <p class="uk-padding">
          Кухня, как ни одна другая комната, требует разумной расстановки
          мебели. Чем удобнее расположены стол, стулья, рабочая зона, тем меньше
          устает хозяйка во время приготовления пищи. Для того чтобы мебелировка
          помещения соответствовала всем вашим пожеланиям, наилучшим вариантом
          будет сделать кухню по индивидуальным размерам на заказ. Такой набор
          позволит экономно использовать даже маленькую площадь, придаст комнате
          оригинальный вид. Мебельная компания Сокол изготовит для Вас кухонный
          гарнитур по типовому и нестандартному дизайну.
        </p>
      </div>

      <div v-if="!this.$store.state.StConversionZamer">
        <p class="text-center justify-center">© Сокол - 2023</p>
        <div class="text-center">Оставьте заявку на бесплатный замер</div>
        <form-send
          resource="Footer"
          btn-title="Записаться на замер"
          :skidka="this.$store.state.StDiscountCount"
        />
      </div>
      <div
        class="text-h6 text-center q-mb-md"
        v-if="this.$store.state.StConversionZamer"
      >
        <q-icon color="primary" style="font-size: 4em" name="check" />
        <br />
        Ваша заявка принята
        <br />
        Скоро мы Вам перезвоним<span class="text-lime-12"></span>
        <br />
      </div>

      <div>
        <ul class="uk-list">
          <li>
            <a href="mailto:kom-sokol@yandex.ru">
              <q-icon
                name="email"
                class="q-mr-sm"
                color="teal-14"
                c
                size="2em"
              /><span>kom-sokol@yandex.ru</span></a
            >
          </li>
          <li>
            <a href="tel:+79622972289">
              <q-icon
                name="call"
                class="q-mr-sm"
                color="lime-12"
                c
                size="2em"
              /><span>8 (962)-297-2289</span></a
            >
          </li>
          <li>
            <a
              href="https://api.whatsapp.com/send?phone=79622972289"
              target="_blank"
              rel="noopener noreferrer"
            >
              <q-icon
                name="whatsapp"
                class="q-mr-sm"
                color="green"
                c
                size="2em"
              /><span>8 (962)-297-2289</span></a
            >
          </li>
                    <li>
                      <a
                        href="https://t.me/sokolmeb"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <q-icon
                          name="fab fa-telegram"
                          class="q-mr-sm"
                          color="primary"
                          c
                          size="2em"
                        /><span>Telegram</span></a
                      >
                    </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import FormSend from "../../Layouts/FormSend";
export default {
  name: "dvmFooter",
  components: { FormSend },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
