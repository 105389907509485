<template>
  <div :id="id" uk-modal>
    <div class="uk-modal-dialog uk-modal-body uk-border-rounded brd">
      <button class="uk-modal-close-default title" type="button" uk-close></button>
      <div class="uk-modal-header uk-padding-remove-top">
        <h2 class="uk-modal-title uk-text-center title"><b>{{title}}</b></h2>
      </div>
      <div class="uk-text-center uk-margin-small-top" v-if="type==='visit'">
        <span>Вы посетили наш сайт </span>
        <span class="title">{{content}}</span>
        <span> раз, но так и не оставили заявку.</span>
        <br>
        <span>Введите телефон, и мы обязательно Вам </span> <span class="title">поможем</span>
        <br>



        <q-input  class="q-ma-lg" color="blue" v-model="clientPhone" label="Ваш телефон">
          <template v-slot:prepend>
            <q-icon color="blue" name="phone" />
          </template>
        </q-input>
        <q-btn class="q-mt-md" color="primary" label="Перезвоните мне" />
<div class="q-mt-lg"><span class="uk-h4">ИЛИ</span></div>


        <div class="q-pa-lg q-gutter-sm">

          <q-btn round color="primary" icon="phone" type="a" :href="'tel://'+dvmPhone"/>
          <q-btn round color="primary" icon="whatsapp" type="a" :href="'https://wa.me/'+dvmPhone+'?text=Вопрос%20с%20сайта!'" target="__blank"/>

        </div>


<!--        <button class="uk-button uk-button-danger uk-border-rounded">Закрыть, и больше не показывать</button>-->
        <span>Вы всегда можете позвонить нам:  </span>
        <span class="title"><a class="uk-link" :href="'tel://'+dvmPhone">{{dvmPhone}}</a></span>
      </div>
    </div>
  </div>





  <div :id="id" uk-modal>
    <div class="uk-modal-dialog uk-modal-body">
      <h2 class="uk-modal-title"></h2>

    </div>
  </div>
</template>


<script>
import {dvmPhone} from "../../const/dvmCfg";
import {ref} from "vue";

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    content: {
      type: [String, Number]
    },
    leadPhone: {
      type: String,
    },
    leadConversion: {
      type: String
    },
    type: {
      type: String,
      required: true
    }


  },
  name: "InfoModal",
  setup(props) {
 const clientPhone = ref('123')

return{
  dvmPhone,
  clientPhone

}
  }
}



</script>

<style scoped>
.brd {
  border: 1px solid #1e87f0;
}
.title {
  color: #1e87f0;
}
.title-text-bg {
  line-height: 1.1;
  padding: 1px 10px 3px;
  background: rgba(255, 235, 59, 1);
  /* box-shadow: 10px 0 rgba(255, 235, 59, 1), -10px 0 rgba(255, 235, 59, 1); */
  color: #000;
}

.head-h1-shadow {
  text-shadow: 1px 0 1px #000, 0 1px 1px #000, -1px 0 1px #000, 0 -1px 1px #000;
}
</style>