<template>

  <q-dialog v-model="state"
            persistent
            transition-show="flip-down"
            transition-hide="flip-up"
  >
    <q-card class="bg-dark text-white">

      <q-card-section class="row items-center q-pt-none q-pr-none q-pb-none">
        <q-space />
        <q-btn dense flat icon="close"   v-close-popup>
        </q-btn>
      </q-card-section>


      <q-card-section v-if="this.$store.state.StConversionLeadMagnet">

        <div class="text-h6 text-center">
          <q-icon color="teal-14" style="font-size: 4em;" name="check" />

          <br>
          Ваша заявка принята
          <br>
          Скоро мы Вам перезвоним<span class="text-teal-14"></span>
          <br>

        </div>
<!--        <div class="uk-text-center">-->
<!--        <p class="uk-margin-small-top">А пока вы можете подписаться на наш инстаграм, где мы регулярно выкладываем фото работ, <br>делимся полезными советами и проводим розыгрыши</p>-->
<!--          <q-btn-->
<!--              color="lime-12"-->
<!--              class="text-black"-->
<!--              label="Подписаться"-->
<!--              type="a" href="https://www.instagram.com/dvm_krasnodar/"-->
<!--              target="__blank"-->
<!--              rel="noreferrer"-->
<!--          />-->

<!--      </div>-->
      </q-card-section>



      <q-card-section v-if="!this.$store.state.StConversionLeadMagnet">
        <div class="text-h6 text-center">Постойте, <span class="text-teal-14">не уходите!</span>
          <br>
          оставив заявку <span class="text-teal-14">прямо сейчас</span>
          <br>
          Ваша скидка будет состовлять: <span><s>{{ this.$store.state.StDiscountCount }}%</s>
            <q-badge color="teal-14" align="top" class="text-black" style="font-size: 1.25rem">{{ this.$store.state.StDiscountCount+3 }}%</q-badge></span>
        </div>
      </q-card-section>

      <form-send btnTitle="Отсавить заявку" :skidka="this.$store.state.StDiscountCount+3" resource="LeadMagnet" v-if="!this.$store.state.StConversionLeadMagnet"/>

    </q-card>
  </q-dialog>

</template>

<script>


import FormSend from "../Layouts/FormSend";
export default {
  name: "ModalLeadMagnet",
  components: {FormSend},
  props: {
    state: {
      type: Boolean,
      required: true
    },
  },
  setup() {



    return{

    }
  }

}
</script>

<style scoped>

</style>