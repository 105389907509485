<template>
  <h3-title :title="title"/>
  <div class="uk-grid-match uk-grid-small uk-child-width-1-3@m uk-child-width-1-2@s justify-center" uk-grid>
    <div v-for="q in question">
      <div
          class="uk-card uk-card-default uk-box-shadow-hover-large curPoint"
          @click='setqval(q)'
          :key="q.id"
      >
        <q-img
            loading = "lazy"
            fit = "contain"
            :src="q.img"
            :alt="q.title + ' кухня | DVM - Мебель в Краснодаре'"
            class="rounded-borders"
        >
          <div class="absolute-top text-center text-subtitle text-lime-12">
            {{q.title}}
          </div>
          <div class="absolute-center" style="background-color:transparent;" >
            <q-icon
                style="font-size: 5em"
                class="text-primary"
                name="done"
                :class="{'uk-hidden': q.title!==this.$store.state.q5val }"
            />
          </div>
        </q-img>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import H3Title from "../../../UiComponents/h3-title";
export default {
  name: "holodilnikKitchen",
  components: {H3Title},
  props: {
    title:{
      type: String,
      required: true
    }
  },
  setup(){
    const store = useStore()

    function setqval(arg) {
      store.state.q5val = arg.title
      store.state.q5id = arg.id
      store.state.q5size = arg.size

      store.state.cTest = store.state.cTest + 1



      const fttimeout = () => {
        UIkit.switcher('#quiz-switch').show(store.state.cTest-1)

      }
      setTimeout(fttimeout, 300)
      const tm = () => {
        UIkit.scroll('', {offset: store.state.quizOffset}).scrollTo('#topQuiz')
      }
      setTimeout(tm, 600)
    }


    const question = [
      {
        active: false,
        id: 1,
        size: '60',
        title: 'Шириной 60см',
        img: 'img/quiz/holodilnik/hl-2.webp'
      },
      {
        active: false,
        id: 2,
        size: '90',
        title: 'Шириной 90см',
        img: 'img/quiz/holodilnik/hl-11.webp'
      },
      {
        active: false,
        id: 3,
        size: '60',
        title: 'Встроенный',
        img: 'img/quiz/holodilnik/hl-3.webp'
      },
    ]
    return{
      question,
      setqval
    }
  }
}
</script>

<style scoped>
.text-subtitle {
  padding: 3px;
  background: rgba(0, 0, 0, 0.7);
}
</style>