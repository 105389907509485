<template>
  <div class="doubts">
    <!--  <div v-if="$q.platform.is.desktop">-->
    <!--    <h2 class="text-center uk-margin-remove-top uk-margin-small-bottom">Кухня вашей мечты станет реальностью</h2>-->
    <!--    <h5 class="text-center uk-margin-remove-top uk-text-primary">Мы позаботимся о том, чтобы вас больше не терзали сомнения</h5>-->
    <!--  </div>-->

    <!--  <div v-if="$q.platform.is.mobile">-->
    <!--    <h2 class="text-center text-h4 uk-margin-remove-top uk-margin-small-bottom">Кухня вашей мечты станет реальностью</h2>-->
    <!--    <h5 class="text-center text-h6 uk-margin-remove-top uk-text-primary">Мы позаботимся о том, чтобы вас больше не терзали сомнения</h5>-->
    <!--  </div>-->

    <div
      class="uk-position-top-left icon posleft1 uk-hidden@m uk-position-large"
      uk-tooltip="Нужна красивая кухня из качественных материалов, а попадается ширпотреб?"
    >
      <q-icon name="help" />
    </div>
    <div
      class="uk-position-top-left icon posleft2 uk-hidden@m uk-position-large"
      uk-tooltip="На красивых сайтах цены наверняка завышены!"
    >
      <q-icon name="help" />
    </div>
    <div
      class="uk-position-top-right icon posright1 uk-hidden@m uk-position-large"
      uk-tooltip="Отдам столько денег, а она у меня через год развалится!"
    >
      <q-icon name="help" />
    </div>
    <div
      class="uk-position-top-right icon posright2 uk-hidden@m uk-position-large"
      uk-tooltip="Сорвут все сроки, и будут нелепо отмазываться"
    >
      <q-icon name="help" />
    </div>

    <div class="uk-position-center">
      <br />
      <br />

      <br />
      <br />
      <br />

      <div
        class="uk-visible@m uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-3@l uk-text-center"
        uk-grid="parallax: 150"
      >
        <div>
          <div class="uk-card uk-card-default uk-card-body uk-hidden">Item</div>
        </div>
        <div>
          <div class="uk-card uk-card-default uk-card-body uk-hidden">Item</div>
        </div>
        <div>
          <div class="uk-card uk-card-default uk-card-body uk-hidden">Item</div>
        </div>
        <div>
          <div class="uk-card uk-card-default uk-card-body uk-hidden">Item</div>
        </div>
        <div>
          <div class="uk-card uk-card-default uk-card-body uk-hidden">Item</div>
        </div>
        <div>
          <div class="uk-card uk-card-default uk-card-body uk-hidden">Item</div>
        </div>
        <div class="uk-flex uk-flex-center">
          <div class="uk-card thought uk-padding-small mouse-parallax-bg">
            Нужна красивая кухня из качественных материалов, а попадается
            ширпотреб?
          </div>
        </div>
        <div>
          <div class="uk-card uk-card-default uk-card-body uk-hidden">Item</div>
        </div>
        <div class="uk-flex uk-flex-center">
          <div class="uk-card thought uk-padding-small mouse-parallax-bg">
            На красивых сайтах цены наверняка завышены!
          </div>
        </div>
        <div class="uk-flex uk-flex-center">
          <div class="uk-card thought uk-padding-small mouse-parallax-bg">
            Отдам столько денег, а она у меня через год развалится!
          </div>
        </div>
        <div>
          <div class="uk-card uk-card-default uk-card-body uk-hidden">Item</div>
        </div>
        <div class="uk-flex uk-flex-center">
          <div class="uk-card thought uk-padding-small mouse-parallax-bg">
            Сорвут все сроки, и будут нелепо отмазываться
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";

export default {
  name: "DvmDoubts",
  setup() {
    onMounted(() => {
      function getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
      }

      let bg = document.querySelectorAll(".mouse-parallax-bg");
      for (let i = 0; i < bg.length; i++) {
        let rx = getRandomArbitrary(40, 60);
        let rr = getRandomArbitrary(0, 1);
        let res = "";
        let resx = 0;
        if (rr > 0.5) {
          res = "+";
          resx = "50";
        } else {
          res = "-";
          resx = "30";
        }
        window.addEventListener("mousemove", function (e) {
          let x = e.clientX / window.innerWidth;
          let y = e.clientY / window.innerHeight;
          bg[i].style.transform =
            "translate(" + res + x * rx + "px, -" + y * rx + "px)";
        });
      }
    });

    return {};
  },
};
</script>

<style scoped>
.icon {
  font-size: 30px;
  color: #00bfa5;
}
.posleft1 {
  top: 300px;
}
.posleft2 {
  top: 400px;
}
.posright1 {
  top: 300px;
}
.posright2 {
  top: 400px;
}

.doubts {
  height: 650px;
  background: url(/img/test/doubts-bg.jpg) no-repeat center bottom;
  position: relative;
  z-index: 0;
  padding: 50px 0;
}
.doubtsItem {
  border: 2px solid;
  border-radius: 50px;
  border-color: #00bfa5;
}

.thought {
  display: flex;

  color: white;
  background-color: #00bfa5;
  padding: 20px;
  border-radius: 30px;
  min-width: 40px;
  max-width: 220px;
  min-height: 40px;
  margin: 20px;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.thought:before,
.thought:after {
  content: "";
  background-color: #00bfa5;
  border-radius: 50%;
  display: block;
  position: absolute;
  z-index: -1;
}
.thought:before {
  width: 44px;
  height: 44px;
  top: -12px;
  left: 28px;
  box-shadow: -50px 30px 0 -12px #00bfa5;
}
.thought:after {
  bottom: -10px;
  right: 26px;
  width: 30px;
  height: 30px;
  box-shadow: 40px -34px 0 0 #00bfa5, -28px -6px 0 -2px #00bfa5,
    -24px 17px 0 -6px #00bfa5, -5px 25px 0 -10px #00bfa5;
}
</style>
