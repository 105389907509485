<template>
  <div uk-grid>
    <div class="uk-width-expand@s uk-width-auto@m">
      <div class="uk-flex uk-flex-center">
        <img src="img/quiz/2/3-n.webp" alt="DVM | Мебель на заказ">
      </div>
    </div>
    <div class="uk-width-auto@s uk-width-expand@m">
      <h5 class="uk-text-center">1м = 100см</h5>
      <form v-on:submit.prevent>
        <quiz-input size="A"/>
        <quiz-input size="B"/>
        <quiz-input size="C"/>


        <div class="uk-text-center uk-margin">
          <quiz-button
              v-if="this.$store.state.q2validl1 && this.$store.state.q2validl2 && this.$store.state.q2validl3"
          >Далее
          </quiz-button>
          <div id="btn"></div>
        </div>
      </form>
    </div>

  </div>
</template>

<script>
import QuizInput from "../ui/quizInput";
import QuizButton from "../ui/quizButton";
export default {
  name: "form3",
  components: {QuizButton, QuizInput}
}
</script>

<style scoped>

</style>