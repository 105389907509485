import { createStore } from "vuex";

export default createStore({
  state: {
    StDiscountDate: "date: 2023-11-31",
    StDiscountMonth: "Ноября",
    StDiscountCount: 10,

    StConversionPhone: "",
    StConversionLeadMagnet: false,
    StConversionModal: false,
    StConversionZamer: false,
    StModalSendPhoneShow: false,

    quizOffset: 40,

    quizStolVal: 0,
    quizLdspVal: 0,
    quizPriceFurn: 0,

    quizMetrLdspPrice: 9600,

    quizMetrFasadPVHPrice: 9000,
    quizMetrFasadPLASTIKPrice: 11100,
    quizMetrFasadKRASKAPrice: 16400,
    quizMetrFasadKOMBIPrice: 8100,
    quizMetrFasadDEREVOPrice: 35000,
    quizMetrFasadSHPONPrice: 18500,

    quizMetrStol26Price: 2998,
    quizMetrStol38Price: 11987,
    quizMetrStolAKRILPrice: 21413,
    quizMetrStolKVARZPrice: 29101,
    quizMetrStolDEREVOPrice: 26305,

    quizMetrFartukDSPprice: 2998,
    quizMetrFartukMDFprice: 9509,
    quizMetrFartukSTEKLOprice: 13132,

    quizFurnEconomCount: 0.7,
    quizFurnStandartCount: 1.6,
    quizFurnLuxCount: 3.7,

    quizRuchkaScobaCount: 0.1,
    quizRuchkaTorecCount: 0.23,
    quizRuchkaKraskaPrice: 2355,
    quizRuchkaGolaPrice: 1600,

    quizShowPrice: false,

    StLeadConversion: false,

    cTest: 1,

    q1val: "0",
    q1id: "",

    q3val: "",
    q3id: "",

    q4val: "",
    q4id: "",

    q5val: "",
    q5id: "",
    q5size: "",

    q6val: "",
    q6id: "",

    q7val: "",
    q7id: "",

    q8val: "",
    q8id: "",

    q9val: "",
    q9id: "",

    q10val: "телефон",
    q10id: "",
    quizDone: false,
    qphone: "",
    qprice: 0,

    q2l1: "",
    q2l2: "",
    q2l3: "",

    q2validl1: false,
    q2validl2: false,
    q2validl3: false,

    qtel: "",
  },
  mutations: {},
  actions: {},
  modules: {},
});
