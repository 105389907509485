<template>
<h3-title :title="title"/>
  <div class="uk-grid-match uk-grid-small uk-child-width-1-3@m uk-child-width-1-2@s justify-center" uk-grid>

    <div v-for="q in question">
      <div
          class="uk-card uk-card-default uk-box-shadow-hover-large curPoint"
          @click='setqval(q)'
          :key="q.title"
      >
        <q-img
            loading = "lazy"
            fit = "contain"
            :src="q.img"
            :alt="q.title + ' кухня | DVM - Мебель в Краснодаре'"
            class="rounded-borders"
        >
          <div class="absolute-top text-center text-subtitle text-lime-12">
            {{q.title}}
          </div>
          <card-price :oneVal="q.price" :twoVal="quizFartuk"/>
          <div class="absolute-center" style="background-color:transparent;" >
            <q-icon
                style="font-size: 5em"
                class="text-primary"
                name="done"
                :class="{'uk-hidden': q.title!==this.$store.state.q6val }"
            />
          </div>
        </q-img>
      </div>

    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import H3Title from "../../../UiComponents/h3-title";
import {computed} from "vue";
import CardPrice from "../components/cardPrice";


export default {
  name: "fartukKitchen",
  components: {CardPrice, H3Title},
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup(){
    const store = useStore()

    const fartukL2 = computed(() => !Number(store.state.q2l2) ? Number(store.state.q2l2) : Number(store.state.q2l2) )
    const fartukL3 = computed(() => !Number(store.state.q2l3) ? Number(store.state.q2l3) : Number(store.state.q2l3) )
    const quizFartuk = computed(() => (Number(store.state.q2l1)+60+fartukL2.value+fartukL3.value)/100)

    const question = [
      {
        active: false,
        id: 1,
        title: 'ЛДСП в пластике',
        description: 'Самый бюджетный вариант',
        img: 'img/quiz/fartuk/dsp.webp',
        price: store.state.quizMetrFartukDSPprice
      },
      {
        active: false,
        id: 2,
        title: 'МДФ с фотопечатью',
        description: '312111',
        img: 'img/quiz/fartuk/mdf.webp',
        price: store.state.quizMetrFartukMDFprice
      },
      {
        active: false,
        id: 3,
        title: 'Стекло с фотопечатью',
        description: '312222',
        img: 'img/quiz/fartuk/steklo.webp',
        price: store.state.quizMetrFartukSTEKLOprice
      }
    ]

    function setqval(arg) {
      store.state.q6val = arg.title
      store.state.q6id = arg.id
      store.state.cTest = store.state.cTest + 1



      const fttimeout = () => {
        UIkit.switcher('#quiz-switch').show(store.state.cTest-1)

      }
      setTimeout(fttimeout, 300)
      const tm = () => {
        UIkit.scroll('', {offset: store.state.quizOffset}).scrollTo('#topQuiz')
      }
      setTimeout(tm, 600)

    }
    return{
      setqval, question, quizFartuk
    }
  }
}
</script>

<style scoped>
.text-subtitle {
  padding: 3px;
  background: rgba(0, 0, 0, 0.7);
}
</style>