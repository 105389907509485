<template>
  <section class="uk-section-secondary uk-padding" style="position: relative">
    <div class="topSplash"></div>

    <div
      uk-grid
      class="uk-child-width-1-1 uk-hidden@m uk-text-center text-teal-14"
    >
      <div>
        <div class="dNot dNotMobile text-teal-14 uk-margin">
          Качественные материалы
        </div>
      </div>
      <div>
        <div class="dNot dNotMobile text-teal-14 uk-margin">Доступные цены</div>
      </div>
      <div>
        <div class="dNot dNotMobile text-teal-14 uk-margin">
          Гарантия 12 месяцев
        </div>
      </div>
      <div>
        <div class="dNot dNotMobile text-teal-14 uk-margin">
          Работа по договору
        </div>
      </div>
    </div>

    <div class="uk-flex uk-flex-center text-teal-14 uk-visible@m">
      <div class="dNot">Качественные материалы</div>
      <div class="dNot uk-margin-left">Доступные цены</div>
      <div class="dNot uk-margin-left">Гарантия 12 месяцев</div>
      <div class="dNot uk-margin-left">Работа по договору</div>
    </div>
    <div class="topSplashBottom"></div>
  </section>
</template>

<script>
import { ref } from "vue";

export default {
  name: "dvmDoubtsNot",
};
</script>

<style scoped>
.topSplash {
  height: 4px;
  width: 100%;
  position: absolute;
  content: "";
  background: url(/img/test/how-we-work-people-plash1.png) repeat-x;
  top: -4px;
  left: 0;
}

.topSplashBottom {
  height: 4px;
  width: 100%;
  position: absolute;
  transform: scale(1, -1);
  content: "";
  background: url(/img/test/how-we-work-people-plash1.png) repeat-x;
  bottom: -4px;
  left: 0;
  z-index: 2;
}

.dNot {
  padding: 10px;
  font-size: 20px;
  border: 2px solid;
  border-color: #00bfa5;
  border-radius: 3px;
}

.dNotMobile {
  border-radius: 5px;
}
</style>
