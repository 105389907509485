<template>
	<div class="uk-margin-medium-top uk-margin-medium-bottom">
	<h2-title :title="title" color="text-teal-14"/>
	</div>





	<div id="photo" class="uk-child-width-1-1@s uk-child-width-1-2@m uk-child-width-1-3@l uk-grid-small uk-grid-match" uk-grid="masonry: true">
<!--		<img src="/img/newKitchen/1.png" alt="">-->
<!--		<img src="/img/newKitchen/2.png" alt="">-->

<div v-for="data in projectLoaded">
			<img class="uk-grid-item-match"  :src="'/crop-img/newKitchen/'+data+'-720.webp'" alt="" style="">
</div>

	</div>


	<div class="text-center">
		<q-btn
		       label="Показать еще"
		       @click="loadMore"
		       v-if="kd.length-1 >= projectShow"
		       class="q-ma-xl"
		       size="lg"
		/>
	</div>




	<!--  <div id="photo" class="row justify-center">-->
	<!--    <div class="col-lg-3 col-xl-3 col-md-5 col-sm-5 col-xs-12 q-ma-md"  v-for="data in projectLoaded" :key="data.id">-->
	<!--      <product-card :data="data"/>-->
	<!--    </div>-->
	<!--  </div>-->
	<!--  <div class="text-center">-->
	<!--    <q-btn color="primary"-->
	<!--           label="Показать еще"-->
	<!--           @click="loadMore"-->
	<!--           v-if="kitchenData.length >= projectShow"-->
	<!--           class="q-ma-xl"-->
	<!--           size="lg"-->
	<!--    />-->
	<!--  </div>-->


</template>

<script>
import productCard from "../../UiComponents/productCard";
import H2Title from "../../UiComponents/h2-title";
import {computed, ref} from "vue";
import NewCard from "../../UiComponents/NewCard";
export default {
	name: "dvmGallerey",
	components: {
		NewCard,
		H2Title,
		productCard
	},
	props: {
		title:{
			type: String,
			required: true
		}
	},
	setup() {

		const commentsToShow = 3
		const projectShow = ref(6)

		const arr = []
		let i = 1;
		while (i <= 72) { // выводит 1, затем 2
			arr.push(i)
			i++;
		}

		function shuffleArray(array) {
			for (let i = array.length-1; i > 0; i--) {
				const j = Math.floor(Math.random() * (i + 1));
				const temp = array[i];
				array[i] = array[j];
				array[j] = temp;
			}
			return array;
		}

		const kd = shuffleArray(arr)


		const loadMore = () => {
			// console.log(kd)
			if (projectShow.value > kd.length) return
			projectShow.value = projectShow.value + 3
		}
		const projectLoaded = computed(()=> {
			return kd.slice(0, projectShow.value)
		})
		return{
		 commentsToShow, projectShow, loadMore, projectLoaded, kd
		}
	}
}
</script>

<style scoped lang="scss">
.q-btn {
	position: relative;
	color: #222;
	background: rgb(246,195,127);
	background: linear-gradient(137deg, rgba(246,195,127,1) 7%, rgba(186,146,90,1) 24%, rgba(151,111,64,1) 42%, rgba(246,195,127,1) 75%, rgba(246,195,127,1) 85%);
	text-decoration: none;
	font-size: 1.5em;
	display: inline-block;
	text-transform: uppercase;
	letter-spacing: 1px;

	border-radius: 3px;
	padding: 10px 20px;
	margin: 40px;
	box-shadow: 0 2px 5px 0 rgba(3,6,26,0.15);
	transition: .5s all ease-in-out;
	&:hover {
		cursor: pointer;

		color: #fff;
		outline: 2px solid rgb(93, 74, 50);
		animation: none;
		//animation-play-state: paused;
	}
}
</style>