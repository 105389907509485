<template>
  <div class="uk-container">
    <q-card class="my-card shadow-11 uk-margin-large-bottom" id="calc">
      <q-linear-progress
        stripe
        rounded
        size="10px"
        :value="this.$store.state.cTest / 10"
        :color="clr"
        class="q-mt-sm"
      />

      <q-card-section v-show="this.$store.state.quizDone != false">
        <div
          class="uk-text-center uk-animation-fade"
          v-if="$q.platform.is.desktop"
        >
          <div class="uk-margin-medium-top">
            <h2>Ваш запрос успешно отправлен</h2>
          </div>
          <div class="uk-margin"><i class="far fa-thumbs-up fs"></i></div>
          <div class="uk-margin">
            <h3>
              В скором времени к Вам
              <span
                v-if="
                  this.$store.state.q10id === 2 || this.$store.state.q10id === 3
                "
                >в {{ $store.state.q10val }} поступят результаты расчета</span
              ><span v-else
                >на {{ $store.state.q10val }} позвонит наш менеджер <br />
                и сообщит стоимость</span
              >
            </h3>
          </div>
          <!--            <div class="uk-margin">-->
          <!--              <h4>А пока вы можете подписаться на наш инстаграм, где мы регулярно выкладываем фото работ, <br>делимся полезными советами и проводим розыгрыши</h4>-->
          <!--              <a target="_blank" rel="noreferrer" class="uk-button uk-button-primary uk-border-rounded" href="https://www.instagram.com/dvm_krasnodar/">Подписаться</a>-->
          <!--            </div>-->
        </div>

        <div
          class="uk-text-center uk-animation-fade"
          v-if="$q.platform.is.mobile"
        >
          <div class="uk-margin-medium-top">
            <h4>Ваш запрос успешно отправлен</h4>
          </div>
          <div class="uk-margin"><i class="far fa-thumbs-up fs"></i></div>
          <div class="uk-margin">
            <h5>
              В скором времени к Вам
              <span
                v-if="
                  this.$store.state.q10id === 2 || this.$store.state.q10id === 3
                "
                >в {{ $store.state.q10val }} поступят результаты расчета</span
              ><span v-else
                >на {{ $store.state.q10val }} позвонит наш менеджер <br />
                и сообщит стоимость</span
              >
            </h5>
          </div>
          <!--            <div class="uk-margin">-->
          <!--              <h6>А пока вы можете подписаться на наш инстаграм, где мы регулярно выкладываем фото работ, <br>делимся полезными советами и проводим розыгрыши</h6>-->
          <!--              <a target="_blank" rel="noreferrer" class="uk-button uk-button-primary uk-border-rounded" href="https://www.instagram.com/dvm_krasnodar/">Подписаться</a>-->
          <!--            </div>-->
        </div>
      </q-card-section>
      <q-card-section v-show="this.$store.state.quizDone === false">
        <div class="absolute-top row justify-center">
<!--          <quiz-calc />-->
        </div>

        <!--//debug-->
        <!--          <div>{{ this.$store.state.quizStolVal }} stol</div>-->
        <!--          <div>{{ this.$store.state.quizLdspVal }} ldsp</div>-->
        <!--          <div>{{ this.$store.state.quizPriceFurn }} Furn</div>-->
        <!--          <div>{{ this.$store.state.qprice }} price</div>-->

        <ul
          id="quiz-switch"
          class="uk-subnav uk-hidden uk-subnav-pill uk-flex uk-flex-center"
          uk-switcher="animation: uk-animation-fade"
        >
          <li><a href="#">1</a></li>
          <li><a href="#">2</a></li>
          <li><a href="#">3</a></li>
          <li><a href="#">4</a></li>
          <li><a href="#">5</a></li>
          <li><a href="#">6</a></li>
          <li><a href="#">7</a></li>
          <li><a href="#">8</a></li>
          <li><a href="#">9</a></li>
          <li><a href="#">10</a></li>
        </ul>

        <div id="topQuiz"></div>

        <div class="row">
          <q-btn
            v-show="this.$store.state.cTest > 1"
            color="teal-14"
            icon="undo"
            :uk-tooltip="this.$store.state.cTest > 1 ? 'Назад' : ''"
            :class="{ 'uk-animation-fade': this.$store.state.cTest > 1 }"
            @click="back"
            class="uk-animation-fade curPoint"
            aria-label="Назад"
            padding="5px 10px"
          />
          <q-space />

          <!--  <q-btn-->
          <!--      v-show="this.$store.state.cTest > 1"-->
          <!--      color="primary" icon="redo"-->
          <!--      :disable="this.$store.state.cTest <= 1"-->
          <!--      :uk-tooltip="this.$store.state.cTest > 1 ? 'Назад' : ''"-->
          <!--      :class="{'uk-animation-fade': this.$store.state.cTest > 1 }"-->
          <!--      @click="back"-->
          <!--      class="uk-animation-fade curPoint"-->
          <!--      aria-label="Назад"-->
          <!--      padding="5px 10px"-->

          <!--  />-->
        </div>

        <ul class="uk-switcher uk-margin">
          <li>
            <type-kitchen title="Выберите форму кухни" />
          </li>
          <li>
            <size-kitchen title="Введите размер кухни" />
          </li>
          <li>
            <fasad-kitchen title="Выберите фасад кухни" />
          </li>
          <li>
            <stoleshka-kitchen title="Выберите столешницу" />
          </li>
          <li>
            <holodilnik-kitchen title="Какой холодильник у Вас будет?" />
          </li>
          <li>
            <fartuk-kitchen title="Выберите стеновую панель" />
          </li>
          <li>
            <furnitura-kitchen title="Выберите фурнитуру" />
          </li>
          <li>
            <ruchki-kitchen title="Выберите тип ручек" />
          </li>
          <li>
            <surprise-kitchen
              title="Какой бы вы хотели получить подарок в случае заказа кухни?"
            />
          </li>
          <li>
            <finish-kitchen
              title="Готово. Ваши ответы получены и мы уже приступили к расчету!"
            />
          </li>
        </ul>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { useStore } from "vuex";
import TypeKitchen from "./pages/typeKitchen";
import SizeKitchen from "./pages/sizeKitchen";
import FasadKitchen from "./pages/fasadKitchen";
import StoleshkaKitchen from "./pages/stoleshkaKitchen";
import HolodilnikKitchen from "./pages/holodilnikKitchen";
import FartukKitchen from "./pages/fartukKitchen";
import FurnituraKitchen from "./pages/furnituraKitchen";
import RuchkiKitchen from "./pages/ruchkiKitchen";
import SurpriseKitchen from "./pages/surpriseKitchen";
import FinishKitchen from "./pages/finishKitchen";
import { computed } from "vue";
import QuizCalc from "./calc/quizCalc";
export default {
  name: "quizHome",
  components: {
    QuizCalc,
    FinishKitchen,
    SurpriseKitchen,
    RuchkiKitchen,
    FurnituraKitchen,
    FartukKitchen,
    HolodilnikKitchen,
    StoleshkaKitchen,
    FasadKitchen,
    SizeKitchen,
    TypeKitchen,
  },
  setup() {
    const store = useStore();

    const clr = computed(() => {
      let a = "";
      if (store.state.quizDone) {
        a = "green";
      } else {
        a = "teal-14";
      }
      return a;
    });

    function back() {
      if (store.state.cTest > 1) {
        store.state.cTest = store.state.cTest - 1;
        const time = () => {
          UIkit.switcher("#quiz-switch").show(store.state.cTest - 1);
        };
        setTimeout(time, 300);
        const tm = () => {
          UIkit.scroll("", { offset: store.state.quizOffset }).scrollTo(
            "#topQuiz"
          );
        };
        setTimeout(tm, 600);
      }
    }
    return {
      back,
      clr,
    };
  },
};
</script>

<style>
.fs {
  font-size: 5rem;
  color: #0e6dcd;
}
button[disabled] {
  color: #999999;
  border: #f8f8f8 1px solid;
}

button[disabled]:hover {
  color: #999999;
  background: #f8f8f8;
}

.uknew {
  border: #0e6dcd 2px solid;
}

.sss {
  color: #0e6dcd;
  border: #0e6dcd 1px solid;
}

.prg {
  padding-top: 10px;
}
.curPoint {
  cursor: pointer !important;
}
.text-subtitle {
  padding: 3px;
}
</style>
