<template>

  <div id="offcanvas-flip" uk-offcanvas="flip: true; overlay: true">
    <div class="uk-offcanvas-bar">

      <button class="uk-offcanvas-close" type="button" uk-close></button>

      <h3>Title</h3>

      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>

    </div>
  </div>



  <q-drawer behavior="desktop" v-model="rightDrawerOpen" side="right" overlay bordered dark>

    <div>
      <q-list bordered separator>


        <q-item clickable v-ripple @click="scroll('#photo','125')">
          <q-item-section avatar>
            <q-icon color="teal-14" name="photo_camera" />
          </q-item-section>
          <q-item-section >Портфолио</q-item-section>
        </q-item>

	      <q-item clickable tag="a" v-ripple @click="scroll('#calc','130')">
		      <q-item-section avatar >
			      <q-icon color="teal-14" name="calculate" />
		      </q-item-section>
		      <q-item-section >Калькулятор стоимости</q-item-section>
	      </q-item>

<!--        <q-item clickable v-ripple @click="scroll('#factory','125')">-->
<!--          <q-item-section avatar>-->
<!--            <q-icon color="teal-14" name="factory" />-->
<!--          </q-item-section>-->
<!--          <q-item-section >Производство</q-item-section>-->
<!--        </q-item>-->

        <q-item clickable v-ripple @click="scroll('#timeline','125')">
          <q-item-section avatar>
            <q-icon color="teal-14" name="timeline" />
          </q-item-section>
          <q-item-section >Этапы работы</q-item-section>
        </q-item>


      </q-list>

    </div>

    <div class="absolute-bottom">
      <div v-if="!this.$store.state.StConversionZamer">
        <h6 class="uk-text-center q-my-sm">
          Запишитесь на <span class="text-teal-14">бесплатный</span> выезд
          нашего лучшего дизайнера
          <br>
          и получите скидку <span class="text-teal-14">{{this.$store.state.StDiscountCount}}%</span>
        </h6>
      <form-send :skidka="this.$store.state.StDiscountCount" btn-title="Записаться на замер" resource="rightBar"/>
      </div>

      <div class="text-h6 text-center q-mb-md" v-if="this.$store.state.StConversionZamer">
        <q-icon color="teal-14" style="font-size: 4em;" name="check" />

        <br>
        Ваша заявка принята
        <br>
        Скоро мы Вам перезвоним<span class="text-lime-12"></span>
        <br>
      </div>
    </div>




    <div class="column justify-center fixed-left">
      <q-btn style="right: 29px"
             class="br"

             padding="10px 2px"
             color="teal-14"
             icon="chevron_right"
             @click="toggleRightDrawer"
      />
    </div>
  </q-drawer>
  <div class="column justify-center fixed-right" style="z-index: 1001;">
    <q-btn
        class="br"
        padding="10px 2px"
        color="teal-14"
        icon="chevron_left"
        @click="toggleRightDrawer"
    />
  </div>
</template>

<script>
import {onMounted, ref} from "vue";
import FormSend from "./FormSend";
import {useQuasar} from "quasar";

export default {
  name: "OffCanvas",
  components: {FormSend},
  props: {

  },
  setup() {
    const $q=useQuasar()
    onMounted(()=>{


    })

    const scroll = (id, offset) => {
      if ($q.platform.is.mobile) {
        offset=offset-40
      }

      UIkit.scroll('',{offset: offset}).scrollTo(id)
      rightDrawerOpen.value=false
    }

    const rightDrawerOpen = ref(false)
    return {
      rightDrawerOpen,
      scroll,
      toggleRightDrawer() {
        rightDrawerOpen.value = !rightDrawerOpen.value
      },
    }
  }
}
</script>

<style scoped>

</style>