<template>

  <q-dialog v-model="state"
            persistent
            transition-show="flip-down"
            transition-hide="flip-up"
  >
    <q-card class="bg-dark text-white">

      <q-card-section class="row items-center q-pt-none q-pr-none q-pb-none">
        <q-space />
        <q-btn @click="state=!state" dense flat icon="close"   v-close-popup>
        </q-btn>
      </q-card-section>


      <q-card-section v-if="this.$store.state.StConversionModal">

        <div class="text-h6 text-center">
          <q-icon color="teal-14" style="font-size: 4em;" name="check" />

          <br>
          Ваша заявка принята
          <br>
          Скоро мы Вам перезвоним<span class="text-teal-14"></span>
          <br>

        </div>
<!--        <div class="uk-text-center">-->
<!--          <p class="uk-margin-small-top">А пока вы можете подписаться на наш инстаграм, где мы регулярно выкладываем фото работ, <br>делимся полезными советами и проводим розыгрыши</p>-->
<!--          <q-btn-->
<!--              color="lime-12"-->
<!--              class="text-black"-->
<!--              label="Подписаться"-->
<!--              type="a" href="https://www.instagram.com/dvm_krasnodar/"-->
<!--              target="__blank"-->
<!--              rel="noreferrer"-->
<!--          />-->

<!--        </div>-->
      </q-card-section>



      <q-card-section v-if="!this.$store.state.StConversionModal">
        <div class="text-h6 text-center">Вероятно у Вас возник <span class="text-teal-14">вопрос</span>
          <br>
          Оставьте свой <span class="text-teal-14">телефон</span>
          <br>
          и мы с радостью Вам <span class="text-teal-14">поможем!</span>

        </div>
      </q-card-section>

      <form-send btnTitle="Перезвоните мне" :skidka="this.$store.state.StDiscountCount" resource="ModalWindow10Visits" v-if="!this.$store.state.StConversionModal"/>


    </q-card>
  </q-dialog>

</template>

<script>
import FormSend from "../Layouts/FormSend";
export default {
  name: "Modal10Visits",
  components: {FormSend},
  props: {
    state: {
      type: Boolean,
      required: true
    },
  },
}
</script>

<style scoped>

</style>