<template>
  <h3
    v-if="$q.platform.is.desktop"
    class="uk-text-center text-teal-14 uk-margin-remove-top"
  >
    <span class="">{{ title }}</span>
  </h3>
  <h3
    v-if="$q.platform.is.mobile"
    class="uk-text-center text-primary text-h5 uk-margin-remove-top"
  >
    <span class="">{{ title }}</span>
  </h3>
</template>

<script>
export default {
  name: "h3-title",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.bottom {
  border-bottom: #00e5ff 2px solid;
}
</style>
