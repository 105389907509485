<template>
  <div
      class="text-lime-12 absolute-bottom text-center text-subtitle"
      v-if="this.$store.state.quizStolVal>=2"
  >+ <del style="font-size: 12px">{{Math.round(oneVal*twoVal)}}</del> <b>{{Math.round(oneVal*twoVal-oneVal*twoVal*this.$store.state.StDiscountCount/100)}} ₽</b></div>
</template>

<script>
export default {
  name: "cardPrice",
  props: {
    oneVal: {
      type:Number
    },
    twoVal: {
      type:Number
    }
  }
}
</script>

<style scoped>
.text-subtitle {
  padding: 3px;
  background: rgba(0, 0, 0, 0.7);
}
</style>