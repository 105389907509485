<template>
  <div
    class="price bg-teal-14 text-white text-h5 uk-animation-slide-bottom"
    v-if="
      this.$store.state.cTest > 2 &&
      this.$store.state.qprice > 5000 &&
      this.$store.state.quizStolVal >= 2
    "
  >
    Цена:
    <del class="text-h6 text-secondary"> {{ this.$store.state.qprice }} </del>
    <b class="text-white">
      {{
        mr(
          this.$store.state.qprice -
            this.$store.state.qprice * (this.$store.state.StDiscountCount / 100)
        )
      }}
      ₽</b
    >
  </div>
</template>

<script>
import { computed, ref, toRef, toRefs, watchEffect } from "vue";
import { useStore } from "vuex";

export default {
  name: "quizCalc",
  setup() {
    const store = useStore();

    const price = computed(() =>
      mr(
        quizLdsp.value +
          fasadResult.value +
          stolResult.value +
          fartukResult.value +
          furnResult.value +
          ruchkaResult.value
      )
    );

    const mr = (e) => {
      return Math.round(e);
    };

    const ldspL2 = computed(() =>
      !Number(store.state.q2l2)
        ? Number(store.state.q2l2)
        : Number(store.state.q2l2) - 60
    );
    const ldspL3 = computed(() =>
      !Number(store.state.q2l3)
        ? Number(store.state.q2l3)
        : Number(store.state.q2l3) - 60
    );
    const quizLdsp = computed(
      () =>
        ((Number(store.state.q2l1) + ldspL2.value + ldspL3.value) / 100) *
        store.state.quizMetrLdspPrice
    );

    const fasadV = computed(
      () =>
        ((Number(store.state.q2l1) + ldspL2.value + ldspL3.value) * 0.72) / 100
    );
    const fasadN = computed(
      () =>
        ((Number(store.state.q2l1) + ldspL2.value + ldspL3.value) * 0.72) / 100
    );
    const fasadAll = computed(() => fasadN.value + fasadV.value);
    const fasadResult = computed(() => {
      store.state.quizLdspVal = quizLdsp.value;
      if (store.state.q3id === 1) {
        //plastik
        return fasadAll.value * store.state.quizMetrFasadPLASTIKPrice;
      }
      if (store.state.q3id === 2) {
        //PVH
        return fasadAll.value * store.state.quizMetrFasadPVHPrice;
      }
      if (store.state.q3id === 3) {
        //KRASKA
        return fasadAll.value * store.state.quizMetrFasadKRASKAPrice;
      }
      if (store.state.q3id === 4) {
        //KOMBI
        return fasadAll.value * store.state.quizMetrFasadKOMBIPrice;
      }
      if (store.state.q3id === 5) {
        //MASSIV
        return fasadAll.value * store.state.quizMetrFasadDEREVOPrice;
      }
      if (store.state.q3id === 1) {
        //SHPON
        return fasadAll.value * store.state.quizMetrFasadSHPONPrice;
      }
      if (!store.state.q3id) {
        //null
        return 0;
      }
    });

    const stol = computed(
      () => (Number(store.state.q2l1) + ldspL2.value + ldspL3.value) / 100
    );
    const stolResult = computed(() => {
      store.state.quizStolVal = stol.value;
      if (store.state.q4id === 1) {
        //26
        return stol.value * store.state.quizMetrStol26Price;
      }
      if (store.state.q4id === 2) {
        //38
        return stol.value * store.state.quizMetrStol38Price;
      }
      if (store.state.q4id === 3) {
        //akril
        return stol.value * store.state.quizMetrStolAKRILPrice;
      }
      if (store.state.q4id === 4) {
        //kvarz
        return stol.value * store.state.quizMetrStolKVARZPrice;
      }
      if (store.state.q4id === 5) {
        //derevo
        return stol.value * store.state.quizMetrStolDEREVOPrice;
      }
      if (!store.state.q4id) {
        //null
        return 0;
      }
    });

    const priceFurn = () => {
      return (store.state.quizPriceFurn = price);
    };
    const fartukL2 = computed(() =>
      !Number(store.state.q2l2)
        ? Number(store.state.q2l2)
        : Number(store.state.q2l2)
    );
    const fartukL3 = computed(() =>
      !Number(store.state.q2l3)
        ? Number(store.state.q2l3)
        : Number(store.state.q2l3)
    );
    const quizFartuk = computed(
      () =>
        (Number(store.state.q2l1) + 60 + fartukL2.value + fartukL3.value) / 100
    );
    const fartukResult = computed(() => {
      if (store.state.q6id === 1) {
        //DSP
        priceFurn();
        return quizFartuk.value * store.state.quizMetrFartukDSPprice;
      }
      if (store.state.q6id === 2) {
        //MDF
        priceFurn();
        return quizFartuk.value * store.state.quizMetrFartukMDFprice;
      }
      if (store.state.q6id === 3) {
        //STEKLO
        priceFurn();
        return quizFartuk.value * store.state.quizMetrFartukSTEKLOprice;
      }
      if (!store.state.q6id) {
        //null
        return 0;
      }
    });

    const furnResult = computed(() => {
      if (store.state.q7id === 1) {
        //econom
        return store.state.quizLdspVal * store.state.quizFurnEconomCount;
      }
      if (store.state.q7id === 2) {
        //standart
        return store.state.quizLdspVal * store.state.quizFurnStandartCount;
      }
      if (store.state.q7id === 3) {
        //lux
        return store.state.quizLdspVal * store.state.quizFurnLuxCount;
      }
      if (!store.state.q7id) {
        //null
        return 0;
      }
    });

    const ruchkaResult = computed(() => {
      if (store.state.q8id === 1) {
        //skoba
        return store.state.quizLdspVal * store.state.quizRuchkaScobaCount;
      }
      if (store.state.q8id === 2) {
        //torec
        return store.state.quizLdspVal * store.state.quizRuchkaTorecCount;
      }
      if (store.state.q8id === 3) {
        //kraska
        return store.state.quizStolVal * 3 * store.state.quizRuchkaKraskaPrice;
      }
      if (store.state.q8id === 4) {
        //gola
        return store.state.quizStolVal * 3 * store.state.quizRuchkaGolaPrice;
      }
      if (!store.state.q8id) {
        //null
        return 0;
      }
    });

    store.state.qprice = price;

    return {
      fasadResult,
      price,
      ruchkaResult,
      stol,
      quizLdsp,
      mr,
    };
  },
};
</script>

<style scoped>
.price {
  padding: 0px 5px 0px 5px;
  border-radius: 0px 0px 5px 5px;
}
</style>
