<template>
  <div class="materials q-pt-lg">
    <div v-if="$q.platform.is.desktop">
      <h2
        class="text-center uk-margin-remove-top uk-margin-small-bottom text-white"
      >
        Кухня из каких материалов вам по душе?
      </h2>
      <h5 class="tcolor text-center uk-margin-remove-top">
        От классических фасадов из дерева до ультрасовременных
      </h5>
    </div>

    <div v-if="$q.platform.is.mobile">
      <h2
        class="text-center text-h4 uk-margin-remove-top uk-margin-small-bottom text-white"
      >
        Кухня из каких материалов вам по душе?
      </h2>
      <h5 class="tcolor text-center text-h6 uk-margin-remove-top">
        От классических фасадов из дерева до ультрасовременных
      </h5>
    </div>

    <div class="uk-container">
      <div
        class="uk-grid-match uk-grid-small uk-child-width-1-3@m uk-child-width-1-2@s"
        uk-grid
      >
        <div v-for="q in question">
          <div class="col-4 uk-box-shadow-hover-large" :key="q.title">
            <q-img
              loading="lazy"
              fit="contain"
              :src="q.img"
              :alt="q.title + ' кухня | DVM - Мебель в Краснодаре'"
              class="rounded-borders"
            >
              <div class="absolute-top text-center text-subtitle text-white">
                {{ q.title }}
              </div>

              <div class="absolute-bottom rm">
                <q-btn
                  class="text-black"
                  label="Оставить заявку"
                  @click="sm = !sm"
                />
              </div>
            </q-img>
          </div>
        </div>
      </div>
    </div>
    <!--    <div style="min-height: 100px" class="uk-hidden@m"></div>-->

    <div class="uk-hidden@m">
      <div><dvm-discount /></div>
    </div>
  </div>

  <div class="kitchenMat uk-visible@m">
    <div class="uk-position-center"><dvm-discount /></div>
  </div>

  <modal-zamer :state="sm" />
</template>

<script>
import { useStore } from "vuex";
import DvmDiscount from "./DvmDiscount";
import { ref } from "vue";
import ModalZamer from "../../UiComponents/ModalZamer";

export default {
  name: "DvmMatKitchen",
  components: { ModalZamer, DvmDiscount },
  setup() {
    const sm = ref(false);
    const store = useStore();

    const question = [
      {
        active: false,
        id: 1,
        title: "Пластик",
        description: store.state.quizMetrFasadPLASTIKPrice,
        img: "img/quiz/fasad/hpl.webp",
      },
      {
        active: false,
        id: 2,
        title: "ПВХ пленка",
        description: store.state.quizMetrFasadPVHPrice,
        img: "img/quiz/fasad/pl.webp",
      },
      {
        active: false,
        id: 3,
        title: "Крашенные",
        description: store.state.quizMetrFasadKRASKAPrice,
        img: "img/quiz/fasad/kr.webp",
      },
      {
        active: false,
        id: 4,
        title: "Комбинированные",
        description: store.state.quizMetrFasadKOMBIPrice,
        img: "img/quiz/fasad/komb.webp",
      },
      {
        active: false,
        id: 5,
        title: "Массив дерева",
        description: store.state.quizMetrFasadDEREVOPrice,
        img: "img/quiz/fasad/msv.webp",
      },
      {
        active: false,
        id: 6,
        title: "Шпон",
        description: store.state.quizMetrFasadSHPONPrice,
        img: "img/quiz/fasad/sh.webp",
      },
    ];

    return {
      question,
      sm,
    };
  },
};
</script>

<style scoped lang="scss">
.kitchenMat {
  background: url(/img/test/included-in-the-price-bg.jpg) no-repeat center top;
  height: 1436px;
  position: relative;
}
.materials {
  min-height: 300px;
  position: relative;
  text-align: center;
  background: rgb(15, 78, 99);
  background: -moz-linear-gradient(
    top,
    rgba(15, 78, 99, 1) 12%,
    rgba(4, 129, 154, 1) 99%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(12%, rgba(15, 78, 99, 1)),
    color-stop(99%, rgba(4, 129, 154, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(15, 78, 99, 1) 12%,
    rgba(4, 129, 154, 1) 99%
  );
  background: -o-linear-gradient(
    top,
    rgba(15, 78, 99, 1) 12%,
    rgba(4, 129, 154, 1) 99%
  );
  background: -ms-linear-gradient(
    top,
    rgba(15, 78, 99, 1) 12%,
    rgba(4, 129, 154, 1) 99%
  );
  background: linear-gradient(
    to bottom,
    rgba(15, 78, 99, 1) 12%,
    rgba(4, 129, 154, 1) 99%
  );
}

.text-subtitle {
  padding: 3px;
  background: rgba(0, 0, 0, 0.7);
}

.rm {
  background: rgba(0, 0, 0, 0);
}
.tcolor {
  color: #00bfa5;
}

.q-btn {
	color: #222;
	background: rgb(246,195,127);
	background: linear-gradient(137deg, rgba(246,195,127,1) 7%, rgba(186,146,90,1) 24%, rgba(151,111,64,1) 42%, rgba(246,195,127,1) 75%, rgba(246,195,127,1) 85%);
	//display: inline-block;
	//text-transform: uppercase;
	//
	//box-shadow: 0 2px 5px 0 rgba(3,6,26,0.15);
	//transition: .5s all ease-in-out;
	&:hover {
		color: #fff;
		outline: 2px solid rgb(93, 74, 50);
	}
}
</style>
