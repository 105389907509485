<template>
  <q-dialog
    v-model="state"
    persistent
    transition-show="flip-down"
    transition-hide="flip-up"
  >
    <q-card class="card--zamer bg-dark text-white relative-position" style="overflow: visible !important;">
	    <div class="absolute uk-position-top-center" style="top: -50px; z-index: 300">
		    <img
				    class="bg-dark"
				    style="
                height: 100px;
                width: 100px;
                border: 2px solid #00bfa5;
                border-radius: 50%;
                object-position: top;
                object-fit: cover;
              "
				    src="img/team/designer.png"
				    alt=""
		    />
	    </div>

      <q-card-section class="row items-center q-pt-none q-pr-none q-pb-none">
        <q-space />
        <q-btn @click="state = !state" dense flat icon="close" v-close-popup>
        </q-btn>
      </q-card-section>

      <q-card-section v-if="this.$store.state.StConversionZamer">
        <div class="text-h6 text-center card--content">
          <q-icon color="teal-14" style="font-size: 4em" name="check" />

          <br />
          Ваша заявка принята
          <br />
          Скоро мы Вам перезвоним<span class="text-teal-14"></span>
          <br />
        </div>
        <!--        <div class="uk-text-center">-->
        <!--          <p class="uk-margin-small-top">А пока вы можете подписаться на наш инстаграм, где мы регулярно выкладываем фото работ, <br>делимся полезными советами и проводим розыгрыши</p>-->
        <!--          <q-btn-->
        <!--              color="lime-12"-->
        <!--              class="text-black"-->
        <!--              label="Подписаться"-->
        <!--              type="a" href="https://www.instagram.com/dvm_krasnodar/"-->
        <!--              target="__blank"-->
        <!--              rel="noreferrer"-->
        <!--          />-->

        <!--        </div>-->
      </q-card-section>

      <q-card-section v-if="!this.$store.state.StConversionZamer">
        <div class="text-h6 text-center card--content">
          Закажите <span class="text-teal-14">бесплатную </span>консультацию
          <br />
          нашего <span class="text-teal-14">лучшего </span>дизайнера
          <br />
          И получите скидку
          <span class="text-teal-14"
            >{{ this.$store.state.StDiscountCount }}%</span
          >
        </div>
      </q-card-section>

      <form-send
        :btnTitle="btnTitle ? btnTitle : 'Оставить заявку'"
        :skidka="this.$store.state.StDiscountCount"
        resource="ModalWindow"
        v-if="!this.$store.state.StConversionZamer"
      />
    </q-card>
  </q-dialog>
</template>

<script>
import FormSend from "../Layouts/FormSend";
export default {
  name: "ModalZamer",
  components: { FormSend },
  props: {
    state: {
      type: Boolean,
      required: true,
    },
	  content: {
		  type: String,
	  },
	  btnTitle: {
		  type: String,
	  },

  },
};
</script>

<style scoped>
.card--zamer {
	border: 2px solid #00bfa5;

}
.card--content{
	padding: 30px;
}

</style>
