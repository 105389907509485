<template>
	<div class="button-container">

		<a href="#" class="button button-wiggle"><slot></slot></a>


	</div>
</template>

<script setup>

</script>

<style scoped lang="scss">
.button-container {

	//margin: auto 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	@media screen and (max-width: 930px) {
		flex-direction: column;
	}
}

.button {
	position: relative;
	color: #222;
	background: rgb(246,195,127);
	background: linear-gradient(137deg, rgba(246,195,127,1) 7%, rgba(186,146,90,1) 24%, rgba(151,111,64,1) 42%, rgba(246,195,127,1) 75%, rgba(246,195,127,1) 85%);
	text-decoration: none;
	font-size: 1.5em;
	display: inline-block;
	text-transform: uppercase;
	letter-spacing: 1px;

	border-radius: 3px;
	padding: 10px 20px;
	margin: 40px;
	box-shadow: 0 2px 5px 0 rgba(3,6,26,0.15);
	transition: .5s all ease-in-out;
	&:hover {
		cursor: pointer;

		color: #fff;
		outline: 2px solid rgb(93, 74, 50);
		animation: none;
		//animation-play-state: paused;
	}
}

.button-wiggle {
	animation: wiggle 4s 2s infinite;
}

@keyframes wiggle {
	5%,
	50% {
		transform: scale(1);
	}
	10% {
		transform: scale(0.9);
	}
	15% {
		transform: scale(1.15);
	}
	20% {
		transform: scale(1.15) rotate(-5deg);
	}
	25% {
		transform: scale(1.15) rotate(5deg);
	}
	30% {
		transform: scale(1.15) rotate(-3deg);
	}
	35% {
		transform: scale(1.15) rotate(2deg);
	}
	40% {
		transform: scale(1.15) rotate(0);
	}
}

</style>