<template>
  <section class="uk-section-secondary uk-padding" style="position: relative">
    <div class="topSplash"></div>

    <img
      v-if="$q.platform.is.desktop"
      style="
        position: absolute;
        right: 30px;
        top: -80px;
        height: 150px;
        width: auto;
      "
      class=""
      src="/img/laptop.png"
      alt=""
    />

    <h2 class="text-h3 uk-text-center uk-margin-medium-bottom uk-margin-medium-top" v-if="$q.platform.is.desktop">
      Что вы получите заказав <span class="text-teal-14">мебель</span> в компании Сокол
    </h2>
    <h2 class="text-h5 uk-text-center uk-margin-medium-bottom uk-margin-medium-top" v-if="$q.platform.is.mobile">
      Что вы получите заказав <span class="text-teal-14">бесплатный</span> выезд
      дизайнера?
    </h2>
    <div class="uk-container">
      <div
        class="uk-child-width-1-2@m uk-child-width-1-1"
        uk-grid
        uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 300"
      >
        <div>
          <div uk-grid>
            <div class="uk-width-auto">
              <img
                src="/img/kitchen/ico/ico-6.png"
                style="width: 50px; height: 50px"
                alt=""
              />
            </div>
            <div class="uk-width-expand">
              <p class="text-h5">
                <span class="text-teal-14">Правильный</span> замер помещения
              </p>
            </div>
          </div>
        </div>
        <div>
          <div uk-grid>
            <div class="uk-width-auto">
              <img
                src="/img/kitchen/ico/ico-2.png"
                style="width: 50px; height: 50px"
                alt=""
              />
            </div>
            <div class="uk-width-expand">
              <p class="text-h5">
                <span class="text-teal-14">Демонстрацию</span> образцов
                материалов
              </p>
            </div>
          </div>
        </div>
        <div>
          <div uk-grid>
            <div class="uk-width-auto">
              <img
                src="/img/kitchen/ico/ico-5.png"
                style="width: 50px; height: 50px"
                alt=""
              />
            </div>
            <div class="uk-width-expand">
              <p class="text-h5">
                <span class="text-teal-14">Консультацию</span>, как выгодно
                использовать пространство
              </p>
            </div>
          </div>
        </div>

        <div>
          <div uk-grid>
            <div class="uk-width-auto">
              <img
                src="/img/kitchen/ico/ico-4.png"
                style="width: 50px; height: 50px"
                alt=""
              />
            </div>
            <div class="uk-width-expand">
              <p class="text-h5">
                <span class="text-teal-14">Рекомендации</span> по выбору дизайна
              </p>
            </div>
          </div>
        </div>
        <div>
          <div uk-grid>
            <div class="uk-width-auto">
              <img
                src="/img/kitchen/ico/ico-3.png"
                style="width: 50px; height: 50px"
                alt=""
              />
            </div>
            <div class="uk-width-expand">
              <p class="text-h5">
                <span class="text-teal-14">Подробный</span> дизайн-проект
                мебели
              </p>
            </div>
          </div>
        </div>
        <div class="uk-margin-medium-bottom">
          <div uk-grid>
            <div class="uk-width-auto">
              <img
                src="/img/kitchen/ico/ico-1.png"
                style="width: 50px; height: 50px"
                alt=""
              />
            </div>
            <div class="uk-width-expand">
              <p class="text-h5">
                <span class="text-teal-14">Лучшее</span> решение в рамках
                бюджета
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="uk-text-center">
        <q-btn
          label="Консультация дизайнера"
          @click="sm = !sm"
        />
      </div>
    </div>
    <div class="topSplashBottom"></div>
  </section>

  <modal-zamer :state="sm" />
</template>

<script>
import ModalZamer from "../../UiComponents/ModalZamer";
import { ref } from "vue";
export default {
  name: "dvmBonusZamer",
  components: { ModalZamer },
  setup() {
    const sm = ref(false);
    return {
      sm,
    };
  },
};
</script>

<style scoped lang="scss">

.q-btn {
	color: #222;
	background: rgb(246,195,127);
	background: linear-gradient(137deg, rgba(246,195,127,1) 7%, rgba(186,146,90,1) 24%, rgba(151,111,64,1) 42%, rgba(246,195,127,1) 75%, rgba(246,195,127,1) 85%);
	//display: inline-block;
	//text-transform: uppercase;
	//
	//box-shadow: 0 2px 5px 0 rgba(3,6,26,0.15);
	//transition: .5s all ease-in-out;
	&:hover {
		color: #fff;
		outline: 2px solid rgb(93, 74, 50);
	}
}
.topSplash {
  height: 4px;
  width: 100%;
  position: absolute;
  content: "";
  background: url(/img/test/how-we-work-people-plash1.png) repeat-x;
  top: -4px;
  left: 0;
}

.topSplashBottom {
  height: 4px;
  width: 100%;
  position: absolute;
  transform: scale(1, -1);
  content: "";
  background: url(/img/test/how-we-work-people-plash1.png) repeat-x;
  bottom: -4px;
  left: 0;
}
</style>
