<template>
  <div>
    <nav-bar />
    <dvm-header class="shadow-13" />
    <div class="uk-container uk-flex uk-flex-center uk-hidden@m" style="color: white">
      <nav-rating></nav-rating>
    </div>


<!--    <maria-block />-->

<!--    <dvm-gallerey title="Фото и цены" />-->

<gallerey-masonry title="Портфолио"/>
<!--<video-block></video-block>-->

    <dvm-corusel />

    <dvm-doubts />

    <div class="bgimg">
      <dvm-doubts-not />
      <dvm-mat-kitchen />

      <dvm-bonus-zamer />

      <dvm-quiz title="Расчет стоимости" />

      <TimeLine title="Этапы работы" />

      <!--  <dvm-discount/>-->

      <dvm-parallax v-if="$q.platform.is.desktop" />
      <dvm-article />

      <!--      <dvm-factory title="Наше производство" />-->

      <dvm-footer />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
// const DvmQuiz = defineAsyncComponent({
//   loader: () => import('../components/chunks/kitchen/dvmQuiz'),
//   delay: 200,
//   timeout: 3000,
//   loadingComponent: quizPreloader
// })

import dvmHeader from "../components/chunks/kitchen/dvmHeader";
import DvmQuiz from "../components/chunks/kitchen/dvmQuiz";
const NavBar = defineAsyncComponent(() =>
  import("../components/chunks/kitchen/NavBar")
);
import TimeLine from "../components/chunks/kitchen/TimeLine";
import DvmGallerey from "../components/chunks/kitchen/dvmGallerey";
import NewGallerey from "../components/chunks/kitchen/NewGallerey";
import DvmFactory from "../components/chunks/kitchen/dvmFactory";

import UIkit from "uikit";
import DvmDiscount from "../components/chunks/kitchen/DvmDiscount";
import DvmBonusZamer from "../components/chunks/kitchen/dvmBonusZamer";
import DvmParallax from "../components/chunks/kitchen/dvmParallax";
import DvmFooter from "../components/chunks/kitchen/dvmFooter";
import DvmArticle from "../components/chunks/kitchen/DvmArticle";
import DvmDoubts from "../components/chunks/kitchen/DvmDoubts";
import DvmDoubtsNot from "../components/chunks/kitchen/DvmDoubtsNot";
import DvmMatKitchen from "../components/chunks/kitchen/DvmMatKitchen";
import DvmCorusel from "../components/chunks/kitchen/DvmCorusel";
import MariaBlock from "@/components/chunks/kitchen/MariaBlock.vue";
import GallereyMasonry from "../components/chunks/kitchen/gallereyMasonry";
import VideoBlock from "../components/chunks/kitchen/VideoBlock";
import NavRating from "@/components/chunks/kitchen/NavRating.vue";
//import quizPreloader from "../components/preloader/quizPreloader";
//import NavBar from "../components/chunks/kitchen/NavBar";

window.UIkit = UIkit;

// const DvmFactory = defineAsyncComponent(()=> import('../components/chunks/kitchen/dvmFactory'))

export default {
  name: "Home",
  components: {
    NavRating,
	  VideoBlock,
	  GallereyMasonry,
    MariaBlock,
    DvmCorusel,
    DvmMatKitchen,
    DvmDoubtsNot,
    DvmDoubts,
    DvmArticle,
    DvmFooter,
    DvmParallax,
    DvmBonusZamer,
    DvmDiscount,
    NavBar,
    DvmQuiz,
    DvmFactory,
    DvmGallerey,

    dvmHeader,
    TimeLine,
  },
};
</script>
<style>
body {
  background: #222222;
}
</style>
