<template>
<h3-title :title="title"/>
  <div class="uk-grid-match uk-grid-small uk-child-width-1-4@m uk-child-width-1-2@s" uk-grid>

    <div v-for="q in question">
      <div :id="'ruchka'+ q.id" uk-modal>
        <div class="uk-modal-dialog uk-modal-body uk-border-rounded">
          <button class="uk-modal-close-default" type="button" uk-close></button>
          <h3-title :title="q.title"/>
          <div class="uk-flex uk-flex-center">
            <q-img loading = "lazy" :src="q.img" :alt="q.title + ' кухня | DVM - Мебель в Краснодаре'"/>
          </div>
          <p class="uk-text-center uk-margin">{{q.description}}</p>
        </div>
      </div>

      <div
          class="uk-card curPoint uk-card-default uk-box-shadow-hover-large"
          @click='setqval(q)'
          :key="q.title"
      >
        <q-icon style="z-index: 2; font-size: 2em;" @click.stop="mdl(q)" class="text-primary bg-white uk-border-circle uk-position-top-right uk-position-small" name="help_outline" />
        <q-img
            loading = "lazy"
            fit = "contain"
            :src="q.img"
            :alt="q.title + ' кухня | DVM - Мебель в Краснодаре'"
            class="rounded-borders"
        >
          <div class="absolute-top text-center text-subtitle text-lime-12">
            {{q.title}}
          </div>
                 <card-price :oneVal="q.price.value" :twoVal="1"/>

          <div class="absolute-center" style="background-color:transparent;" >
            <q-icon
                style="font-size: 5em"
                class="text-primary"
                name="done"
                :class="{'uk-hidden': q.title!==this.$store.state.q8val }"
            />
          </div>
        </q-img>
      </div>

    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import H3Title from "../../../UiComponents/h3-title";
import {computed, ref} from "vue";
import CardPrice from "../components/cardPrice";

export default {
  name: "ruchkiKitchen",
  components: {CardPrice, H3Title},
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup() {
    const store = useStore()

    function mdl(arg) {
      //
      UIkit.modal('#ruchka'+ arg.id).show();

    }

    function setqval(arg) {
      store.state.q8val = arg.title
      store.state.q8id = arg.id
      store.state.cTest = store.state.cTest + 1



      const fttimeout = () => {
        UIkit.switcher('#quiz-switch').show(store.state.cTest-1)
      }
      setTimeout(fttimeout, 300)
      const tm = () => {
        UIkit.scroll('', {offset: store.state.quizOffset}).scrollTo('#topQuiz')
      }
      setTimeout(tm, 600)

    }

    const ruSk = computed(()=> store.state.quizLdspVal*store.state.quizRuchkaScobaCount)

    const question = [
      {
        active: false,
        id: 1,
        title: 'Ручка - скоба',
        description: 'Самый бюджетный вариант',
        img: 'img/quiz/ruchka/skoba.webp',
        price: computed(()=> store.state.quizLdspVal*store.state.quizRuchkaScobaCount)
      },
      {
        active: false,
        id: 2,
        title: 'Торцевые ручки',
        description: 'Средний вариант по цене',
        img: 'img/quiz/ruchka/torec.webp',
        price: computed(()=> store.state.quizLdspVal*store.state.quizRuchkaTorecCount)
      },
      {
        active: false,
        id: 3,
        title: 'Интегрированная',
        description: '(возможно сделать только на крашеных МДФ фасадах)',
        img: 'img/quiz/ruchka/kraska.webp',
        price: computed(()=> store.state.quizStolVal*3*store.state.quizRuchkaKraskaPrice)
      },
      {
        active: false,
        id: 4,
        title: 'Ручка - профиль',
        description: 'Принципиально, профиль создает декоративно обыгранную нишу за фасадом для его захвата и открывания рукой. То есть, вместо того, чтобы тянуть фасад за ручку, нужно захватывать и тянуть на себя сам фасад.',
        img: 'img/quiz/ruchka/gola.webp',
        price: computed(()=> store.state.quizStolVal*3*store.state.quizRuchkaGolaPrice)
      }
    ]

    return{
      setqval, mdl, question, ruSk
    }
  }
}
</script>

<style scoped>
.text-subtitle {
  padding: 3px;
  background: rgba(0, 0, 0, 0.7);
}
</style>