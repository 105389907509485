<template>
  <h2 v-if="$q.platform.is.desktop" class="uk-text-center text-white uk-margin-medium-top uk-margin-medium-bottom">
    <span :class="color" class="bottom">{{ title }}</span>
  </h2>
  <h2 v-if="$q.platform.is.mobile" class="uk-text-center text-white text-h4 uk-margin-medium-top uk-margin-medium-bottom">
    <span :class="color" class="bottom">{{ title }}</span>
  </h2>
</template>

<script>
export default {
  name: "h2-title",
  props: {
    title: {
      type: String,
      required: true,
    },
    color: {
      type: String,
    },
  },
};
</script>

<style scoped>
.bottom {
  border-bottom: #00bfa5 2px solid;
  border-top: #00bfa5 2px solid;
}
</style>
