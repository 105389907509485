<script setup>
import {onMounted, ref} from 'vue'

const data = ref(null)

onMounted(()=>{
  (async ()=>{
    data.value = await (await fetch('https://api.sokolmeb.ru/getRating')).json()
    console.log(data.value)
  })()

})

const fakeRating = ref(4.9)
</script>

<template>
  <div class="main">
    <div class="rating">
      <q-spinner color="white" size="1em" v-if="!data" />
      <p class="scale" v-else>{{ data.ratingCount }}</p>
    </div>
    <div class="stars">
      <q-rating
          disable
          v-model="fakeRating"
          max="5"
          size="1.3em"
          color="yellow"
          icon="star_border"
          icon-selected="star"
          icon-half="star_half"
          no-reset
          uk-scrollspy="target: > .q-rating__icon-container; cls: uk-animation-slide-top-small; delay: 300"
      />
      <div>
        <p style="position: relative">
          Рейтинг на основе
          <q-skeleton
              style="position: absolute; top: 0; right: 5px"
              type="text"
              width="20px"
              height="18px"
              v-if="!data"
          />
          <b v-else>{{ data.reviewsCount }}</b> отзывов на <b> <a href="https://2gis.ru/komsomolsk/firm/13229852186381700 " target="_blank">2GIS</a></b>
        </p>
      </div>
    </div>



  </div>
</template>

<style scoped lang="scss">

$red-primary:#ee1a24;

.text-red-primary{
  color: $red-primary!important;
}

.bg-red-primary{
  background: $red-primary!important;
}

.q-skeleton {
  bottom: -4px;
  display: inline-block;
  position: relative;
}

div > .q-icon {
  font-size: 3em;
}
.rating {
  width: 60px;
  height: 100px;
  background: $red-primary;
  flex-shrink: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  color: white;
  margin-right: 5px;
}

.rating > p {
  animation: scale-animation 1s ease infinite;
}
p {
  margin: 0;
  font-size: 0.9em;
  line-height: 1.3;
}

.stars {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .q-rating {
    margin-bottom: 5px;
  }
}
.rating:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 30px solid $red-primary;
  border-right: 30px solid $red-primary;
  border-bottom: 20px solid #1d1d1d;
  position: absolute;
  bottom: 0;
}

.main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
}
@keyframes scale-animation {
  from {
    scale: 1;
    //font-size: 1.5em;
  }
  50% {
    scale: 1.2;
  }
  to {
    scale: 1;
  }
}
a{
  text-decoration: none;
  color: #00bfa5!important;
}
</style>