<template>
  <h3-title :title="title"/>
  <div class="uk-grid-match uk-grid-small uk-child-width-1-3@m uk-child-width-1-2@s justify-center" uk-grid>

    <div v-for="q in question">


<!--      modal-->
      <div :id="'stol'+ q.id" uk-modal>
        <div class="uk-modal-dialog uk-modal-body uk-border-rounded">
          <button class="uk-modal-close-default" type="button" uk-close></button>
          <h3-title :title="q.title"/>
          <div class="uk-flex uk-flex-center">
            <q-img
                loading = "lazy"
                :src="q.img"
                :alt="q.title + ' кухня | DVM - Мебель в Краснодаре'"
            />
          </div>
          <p class="uk-text-center uk-margin">{{q.description}}</p>
        </div>
      </div>
      <!--      /modal-->



      <div
          class="uk-card curPoint uk-card-default uk-box-shadow-hover-large"
          @click='setqval(q)'
          :key="q.title"
      >
        <q-icon style="z-index: 2; font-size: 2em;" @click.stop="mdl(q)" class="text-primary bg-white uk-border-circle uk-position-top-right uk-position-small" name="help_outline" />
        <q-img
            loading = "lazy"
            fit = "contain"
            :src="q.img"
            :alt="q.title + ' кухня | DVM - Мебель в Краснодаре'"
            class="rounded-borders"

        >
          <div class="absolute-top text-center text-subtitle text-lime-12">
            {{q.title}}
          </div>
          <card-price :oneVal="q.price" :twoVal="stol"/>
          <div class="absolute-center" style="background-color:transparent;" >
            <q-icon
                style="font-size: 5em"
                class="text-primary"
                name="done"
                :class="{'uk-hidden': q.title!==this.$store.state.q4val }"
            />
          </div>
        </q-img>
      </div>

    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import H3Title from "../../../UiComponents/h3-title";
import {computed} from "vue";
import CardPrice from "../components/cardPrice";
export default {
  name: "stoleshkaKitchen",
  components: {CardPrice, H3Title},
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup() {
    const store = useStore()
    const ldspL2 = computed(() => !Number(store.state.q2l2) ? Number(store.state.q2l2) : Number(store.state.q2l2)-60 )
    const ldspL3 = computed(() => !Number(store.state.q2l3) ? Number(store.state.q2l3) : Number(store.state.q2l3)-60 )
    const stol = computed(() => (Number(store.state.q2l1)+ldspL2.value+ldspL3.value)/100)

    function mdl(arg) {
      //
      UIkit.modal('#stol'+ arg.id).show();

    }


    function setqval(arg) {
      store.state.q4val = arg.title
      store.state.q4id = arg.id
      store.state.q4size = arg.size
      store.state.cTest = store.state.cTest + 1



      const fttimeout = () => {
        UIkit.switcher('#quiz-switch').show(store.state.cTest-1)

      }
      setTimeout(fttimeout, 300)
      const tm = () => {
        UIkit.scroll('', {offset: store.state.quizOffset}).scrollTo('#topQuiz')
      }
      setTimeout(tm, 600)

    }


    const question = [
      {
        active: false,
        id: 1,
        title: 'HPL - 28мм',
        description: 'Самый бюджетный вариант',
        img: 'img/quiz/stoleshka/28.webp',
        price: store.state.quizMetrStol26Price
      },
      {
        active: false,
        id: 2,
        title: 'HPL - 38мм',
        description: 'Самый популярный вариант',
        img: 'img/quiz/stoleshka/38.webp',
        price: store.state.quizMetrStol38Price
      },
      {
        active: false,
        id: 3,
        title: 'Искуственный камень',
        description: 'Дорогой, но с массой преимуществ',
        img: 'img/quiz/stoleshka/akr.webp',
        price: store.state.quizMetrStolAKRILPrice
      },
      {
        active: false,
        id: 4,
        title: 'Кварц',
        description: 'Самый дорогой вариант',
        img: 'img/quiz/stoleshka/kv.webp',
        price: store.state.quizMetrStolKVARZPrice
      },
      {
        active: false,
        id: 5,
        title: 'Дубовый щит',
        description: 'Красиво, экологично, но требует ухода',
        img: 'img/quiz/stoleshka/dub.webp',
        price: store.state.quizMetrStolDEREVOPrice
      },
    ]
    return{
      question, setqval, mdl, stol
    }
  }
}
</script>

<style scoped>
.text-subtitle {
  padding: 3px;
  background: rgba(0, 0, 0, 0.7);
}
</style>