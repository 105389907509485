<template>
  <h3-title :title="title"/>
  <form1 v-if="this.$store.state.q1id===1"/>
  <form2 v-if="this.$store.state.q1id===2 || this.$store.state.q1id===3"/>
  <form3 v-if="this.$store.state.q1id===4"/>
</template>

<script>
import Form1 from "../forms/form1";
import H3Title from "../../../UiComponents/h3-title";
import Form2 from "../forms/form2";
import Form3 from "../forms/form3";
export default {
  name: "sizeKitchen",
  components: {Form3, Form2, H3Title, Form1},
  props: {
    title:{
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>