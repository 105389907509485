const kitchenData = [
    {
        id: '1',
        filesCount: 5,
        price: '133204',
        desc: '',
        likes: '',
    },
    {
        id: '2',
        filesCount: 10,
        price: '152960',
        desc: '',
        likes: '',
    },
    {
        id: '3',
        filesCount: 6,
        price: '209514',
        desc: '',
        likes: '',
    },
    {
        id: '4',
        filesCount: 5,
        price: '197898',
        desc: '',
        likes: '',
    },
    {
        id: '5',
        filesCount: 8,
        price: '224707',
        desc: '',
        likes: '',
    },
    {
        id: '6',
        filesCount: 8,
        price: '200311',
        desc: '',
        likes: '',
    },
    {
        id: '7',
        filesCount: 8,
        price: '143160',
        desc: '',
        likes: '',
    },
    {
        id: '8',
        filesCount: 4,
        price: '186402',
        desc: '',
        likes: '',
    },
    {
        id: '9',
        filesCount: 6,
        price: '182392',
        desc: '',
        likes: '',
    },
    {
        id: '10',
        filesCount: 10,
        price: '217103',
        desc: '',
        likes: '',
    },
    {
        id: '11',
        filesCount: 6,
        price: '215064',
        desc: '',
        likes: '',
    },
    {
        id: '12',
        filesCount: 9,
        price: '174105',
        desc: '',
        likes: '',
    },
    {
        id: '13',
        filesCount: 8,
        price: '141906',
        desc: '',
        likes: '',
    },
    {
        id: '14',
        filesCount: 6,
        price: '168524',
        desc: '',
        likes: '',
    },
    {
        id: '15',
        filesCount: 5,
        price: '187237',
        desc: '',
        likes: '',
    },
    {
        id: '16',
        filesCount: 7,
        price: '110501',
        desc: '',
        likes: '',
    },
    {
        id: '17',
        filesCount: 3,
        price: '125600',
        desc: '',
        likes: '',
    },
    {
        id: '18',
        filesCount: 6,
        price: '189291',
        desc: '',
        likes: '',
    },
    {
        id: '19',
        filesCount: 7,
        price: '193112',
        desc: '',
        likes: '',
    },
    {
        id: '20',
        filesCount: 5,
        price: '259357',
        desc: '',
        likes: '',
    },
    {
        id: '21',
        filesCount: 5,
        price: '130157',
        desc: '',
        likes: '',
    },
    {
        id: '22',
        filesCount: 7,
        price: '175559',
        desc: '',
        likes: '',
    },
    {
        id: '23',
        filesCount: 7,
        price: '164511',
        desc: '',
        likes: '',
    },
]

export { kitchenData }