<template>
  <div class="uk-margin">
    <label class="uk-form-label" :for="size">

      <span v-if="this.inputVal<1">Введите размер "<b>{{size}}</b>" в см.</span>
      <span class="uk-animation-fade" v-if="this.inputVal>=1 && this.inputVal<99999"><b>{{size}}</b></span>
      <span class="uk-animation-fade"
            v-if="this.inputVal>=1 && this.inputVal<1500"> = {{ this.inputVal / 100 }} м.</span>
      <span class="uk-animation-fade danger" v-if="this.inputVal>1499 && this.inputVal<100000"> не может быть {{
          Math.ceil(this.inputVal / 100 * 100) / 100
        }} м.</span>
      <span class="uk-animation-fade danger" v-if="this.inputVal>99999 && this.inputVal<1000000">А Вы настойчивы!</span>
      <span class="uk-animation-fade danger"
            v-if="this.inputVal>999999 && this.inputVal<10000000">Ну хватит, правда!</span>
      <span class="uk-animation-fade danger"
            v-if="this.inputVal>9999999">Аха-ха! щекотно!</span>
    </label>
    <div class="uk-form-controls">
      <input
          v-model="this.inputVal"
          :class="{'uk-form-success': this.inputVal>=100 && this.inputVal<1499, 'uk-form-danger': this.inputVal>=1499, 'uk-animation-shake': this.inputVal>1499}"
          class="uk-input uk-border-rounded"
          @input="cls"
          :id="size"
          type="text"
          inputmode="numeric"
          placeholder="Размер в см"
          style="font-size: 16px!important;">
    </div>
  </div>
</template>

<script>
import {ref, reactive} from "vue";
import { useStore } from 'vuex'
import {useQuasar, QSpinnerGears} from "quasar";

export default {
  name: "quizInput",
  props:{
    size: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const store = useStore()
    const inputVal = ref('')
    const $q = useQuasar()

    function  cls() {
      inputVal.value=inputVal.value.toString().replace(/[^\d]/g, '')

      if(inputVal.value>=100 && inputVal.value<1499 && props.size === 'A'){
        store.state.q2validl1=true
      }else if(props.size === 'A' && inputVal.value<=99) {
        store.state.q2validl1=false
      }else if(props.size === 'A' && inputVal.value>=1499) {
        store.state.q2validl1=false
      }

      if(inputVal.value>=100 && inputVal.value<1499 && props.size === 'B'){
        store.state.q2validl2=true
      }else if(props.size === 'B' && inputVal.value<=99) {
        store.state.q2validl2=false
      }else if(props.size === 'B' && inputVal.value>=1499) {
        store.state.q2validl2=false
      }

      if(inputVal.value>=100 && inputVal.value<1499 && props.size === 'C'){
        store.state.q2validl3=true
      }else if(props.size === 'C' && inputVal.value<=99) {
        store.state.q2validl3=false
      }else if(props.size === 'C' && inputVal.value>=1499) {
        store.state.q2validl3=false
      }



      if (inputVal.value > 9999999999) {
        inputVal.value = ''
        window.navigator.vibrate([70,30,90])
        $q.notify({
          message: 'Поле ввода очищено.',
          color: 'negative',
          spinner: QSpinnerGears,
          position: 'top'
        })
      }
      if (props.size === 'A'){
        store.state.q2l1 = inputVal.value
      }
      if (props.size === 'B'){
        store.state.q2l2 = inputVal.value
      }
      if (props.size === 'C'){
        store.state.q2l3 = inputVal.value
      }
    }


    return{
inputVal,
      cls
    }
  }
}
</script>

<style scoped>

</style>