<template>
<h2-title :title="title"/>
  <div class="uk-container uk-container-large" id="factory">
  <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slideshow="animation: fade; autoplay: true; pause-on-hover: false; finite: false; autoplay-interval: 3000">

    <ul class="uk-slideshow-items uk-border-rounded">
      <li v-for="item in factoryDate" :key="item.id">
        <div class="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-left">


          <q-img
              :src="'/crop-img/kitchen/factory/'+ item.src + '-480.webp'"
              :ratio="16/9"
              uk-cover
              :alt="item.alt"
          />
        </div>



        <div class="uk-position-center uk-text-center">
          <div class="uk-transition-slide-top-small">
            <h3 v-if="$q.platform.is.desktop" class="uk-margin-remove uk-padding-small  uk-text-center text-lime-12 text-h2 title-3d">{{item.desc}}</h3>
            <h3 v-if="$q.platform.is.mobile" class="uk-margin-remove uk-text-center text-lime-12 text-h5 title-3d">{{item.desc}}</h3>
          </div>
          <div class="uk-transition-slide-bottom-small">
            <div class="uk-padding-small">
              <q-btn class="text-black" color="lime-12" label="Записаться на замер" @click="sm=!sm"/>
            </div>
          </div>
        </div>


          <div class="uk-overlay uk-padding-remove uk-overlay-primary uk-position-center uk-text-center uk-border-rounded uk-transition-shake">


          </div>




        <div class="uk-padding-remove uk-position-bottom uk-text-center uk-transition-slide-bottom">

        </div>

      </li>
    </ul>

    <a class="uk-position-center-left uk-position-small" style="text-decoration: none" href="#" uk-slideshow-item="previous">
      <q-icon class="bg-lime-12 text-black  uk-border-circle q-pa-sm" style="font-size: 1.5em" name="chevron_left"/>
    </a>
    <a class="uk-position-center-right uk-position-small" style="text-decoration: none" href="#" uk-slideshow-item="next">
      <q-icon class="bg-lime-12 text-black  uk-border-circle q-pa-sm" style="font-size: 1.5em" name="chevron_right"/>
    </a>
  </div>

  </div>

  <ModalZamer :state = 'sm'/>

</template>

<script>
import H2Title from "../../UiComponents/h2-title";
import ModalZamer from "../../UiComponents/ModalZamer";
import {ref} from "vue";
export default {
  name: "dvmFactory",
  components: {ModalZamer, H2Title},
  props: {
    title:{
      type: String,
      required: true
    }
  },
  setup() {
    const sm = ref(false)

    const factoryDate = [
      {
        id: 1,
        src: '01',
        alt: '',
        desc: 'Без посредников'
      },
      {
        id: 2,
        src: '02',
        alt: '',
        desc: 'Разумные сроки'
      },
      {
        id: 3,
        src: '03',
        alt: '',
        desc: 'Гарантия качества'
      },
      {
        id: 4,
        src: '04',
        alt: '',
        desc: 'Цена производителя'
      },
      {
        id: 5,
        src: '05',
        alt: '',
        desc: 'Работа по договору'
      },
      {
        id: 6,
        src: '06',
        alt: '',
        desc: 'Сервисное обслуживание'
      },

    ]
    return{
factoryDate,
      sm
    }
  }
}
</script>

<style scoped>
.title-3d {
  text-shadow: 0px 0px 0 rgb(-64, -126, 0), 1px 1px 0 rgb(-127, -189, 0), 2px 2px 0 rgb(-191, -253, 0), 3px 3px 0 rgb(-255, -317, 0), 4px 4px 0 rgb(-319, -381, 0), 5px 5px 4px rgba(0, 0, 0, 1), 5px 5px 1px rgba(0, 0, 0, 0.5), 0px 0px 4px rgba(0, 0, 0, .2);
}
</style>