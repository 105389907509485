<template>
  <h3-title :title="title"/>
  <div class="uk-grid-match uk-grid-small uk-child-width-1-3@m uk-child-width-1-2@s justify-center" uk-grid>


    <div style="position: relative" v-for="q in question">
      <div
          class="uk-card mouse uk-card-default uk-box-shadow-hover-large curPoint"
          @click='setqval(q)'
          :key="q.title"
      >
        <img style="position: absolute; width: 182px; z-index: 1; right: -8px; bottom: -36px;" src="/img/quiz/gift.webp" alt="">
        <q-img
            loading = "lazy"
            fit = "contain"
            :src="q.img"
            :alt="q.title + ' кухня | DVM - Мебель в Краснодаре'"
            class="rounded-borders"
        >
          <div class="absolute-top text-center text-subtitle text-lime-12">
            {{q.title}}
          </div>
          <div class="absolute-center" style="background-color:transparent;" >
            <q-icon
                style="font-size: 5em"
                class="text-primary"
                name="done"
                :class="{'uk-hidden': q.title!==this.$store.state.q9val }"
            />
          </div>
        </q-img>
      </div>

    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import H3Title from "../../../UiComponents/h3-title";

export default {
  name: "surpriseKitchen",
  components: {H3Title},
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup() {
    const store = useStore()

    function setqval(arg) {
      store.state.q9val = arg.title
      store.state.q9id = arg.id
      store.state.cTest = store.state.cTest + 1



      const fttimeout = () => {
        UIkit.switcher('#quiz-switch').show(store.state.cTest-1)

      }
      setTimeout(fttimeout, 300)
      const tm = () => {
        UIkit.scroll('', {offset: store.state.quizOffset}).scrollTo('#topQuiz')
      }
      setTimeout(tm, 600)
    }
    const question = [
      {
        active: false,
        id: 2,
        title: 'Посудосушитель',
        description: 'Самый популярный вариант',
        img: 'img/quiz/surprise/sushka.webp'
      },
      {
        active: false,
        id: 3,
        title: 'Лоток для столовых приборов',
        description: 'Дорогой, но с массой преимуществ',
        img: 'img/quiz/surprise/lotok.webp'
      },
      {
        active: false,
        id: 1,
        title: 'Кухонная мойка',
        description: 'Самый бюджетный вариант',
        img: 'img/quiz/surprise/moika.webp'
      },
    ]


    return{
      setqval, question
    }
  }
}
</script>

<style scoped>
.text-subtitle {
  padding: 3px;
  background: rgba(0, 0, 0, 0.7);
}
</style>