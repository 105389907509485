<template>





  <div>
    <transition appear appear-active-class="uk-animation-fade">
      <q-layout view="lHh lpr lFr" @mouseleave="showExit">
        <off-canvas />

        <q-page-container>
          <!--      <router-link to="/shkaf-na-zakaz">Шкаф</router-link>-->
          <!--      <router-link to="/">кухня</router-link>-->

          <!--<router-view/>-->
          <router-view v-slot="{ Component }">
            <transition name="fade">
              <component :is="Component" />
            </transition>
          </router-view>

          <scroll-top />
        </q-page-container>
        <ModalLeadMagnet :state="modalLeadMagnetShow" />
        <!--    <ModalSendPhone :state="this.$store.state.StModalSendPhoneShow"/>-->
        <Modal10Visits :state="modal10VisitsShow" />
      </q-layout>
    </transition>

    <!--  <div v-if="$q.platform.is.desktop">-->
    <!--&lt;!&ndash;    I'm only rendered on desktop!&ndash;&gt;-->
    <!--  </div>-->

    <!--  <div v-if="$q.platform.is.mobile">-->

    <!--  </div>-->

    <!--<info-modal-->
    <!--    title="Нужна помощь?"-->
    <!--    :id="visitModalId"-->
    <!--    :content="countVisitCookie"-->
    <!--    v-if="countVisitCookie >= 10"-->
    <!--    type = 'visit'-->
    <!--    :leadPhone="leadConversionPhone"-->
    <!--    :leadConversion="leadConversion"-->
    <!--/>-->
    <!--<info-modal-->
    <!--    title="Постойте, не уходите!"-->
    <!--    :id="leadMagnetModalId"-->
    <!--    content=""-->
    <!--    v-if="leadMagnetCookie === null"-->
    <!--    type = 'leadMagnet'-->
    <!--    :leadPhone="leadConversionPhone"-->
    <!--    :leadConversion="leadConversion"-->
    <!--/>-->
  </div>
</template>

<script>
import DvmPreloader from "./components/preloader/DvmPreloader";
import { onMounted, reactive, ref, watch } from "vue";
import InfoModal from "./components/chunks/InfoModal";
import Test from "./components/test";
import DvmHeader from "./components/chunks/kitchen/dvmHeader";
import { useQuasar } from "quasar";
import TimeLine from "./components/chunks/kitchen/TimeLine";
import ModalLeadMagnet from "./components/UiComponents/ModalLeadMagnet";
import OffCanvas from "./components/Layouts/OffCanvas";
import ScrollTop from "./components/Layouts/scrollTop";
import { useStore } from "vuex";
import { nextTick } from "vue";
import ModalSendPhone from "./components/UiComponents/ModalSendPhone";
import Modal10Visits from "./components/UiComponents/Modal10Visits";
import MyTelegram from "@/components/myTelegram";
import ConvButton from "./components/UiComponents/convButton";

export default {
  components: {
	  ConvButton,
    MyTelegram,
    DvmPreloader,
    Modal10Visits,
    ModalSendPhone,
    ScrollTop,
    OffCanvas,
    ModalLeadMagnet,
    TimeLine,
    DvmHeader,
    Test,
    InfoModal,
  },
  setup(props) {
    const store = useStore();
    const $q = useQuasar();
    const cookies = $q.cookies;
    const modalLeadMagnetShow = ref(false);
    const modal10VisitsShow = ref(false);

    store.state.StLeadConversion = cookies.get("leadConversion");
    store.state.StConversionPhone = cookies.get("leadConversionPhone");
    const leadConversionPhone = ref(cookies.get("leadConversionPhone"));
    const countVisitCookie = ref(cookies.get("dvmVisits"));
    const leadMagnetCookie = ref(cookies.get("dvmLeadMagnet"));
    const visitModalId = "visitModal";
    const leadMagnetModalId = "leadMagnetModal";
    const preload = ref(true);

    onMounted(() => {
      if (countVisitCookie.value >= 5) {
        $q.notify({
          progress: true,
          message:
            "<b>" +
            countVisitCookie.value +
            "</b> -  Столько раз вы посетили наш сайт за последнее время. Может быть Вам нужна помощь?",
          color: "dark",
          position: "bottom",
          timeout: "12000",
          html: true,

          actions: [
            {
              label: "Закрыть",
              color: "white",
              handler: () => {
                /* ... */
              },
            },
            {
              label: "Обратный звонок",
              color: "teal-14",
              handler: () => {
                modal10VisitsShow.value = !modal10VisitsShow.value;
              },
            },
          ],
        });
        // cookies.remove("dvmVisits");
      }

      const prl = function () {
        preload.value = false;
      };
      window.addEventListener("load", () => {
        setTimeout(prl, 1200);
      });
    });

    //Ставим куки на кол-во посещений
    if (countVisitCookie.value === null) {
      cookies.set("dvmVisits", "1", { expires: "3d" });
    } else {
      countVisitCookie.value++;
      cookies.set("dvmVisits", countVisitCookie.value, { expires: "3d" });
    }

    //Ставим куки ЛидМагнит
    function showExit(event) {
      if (
        leadMagnetCookie.value === null &&
        store.state.StLeadConversion === null
      ) {
        if (event.clientY < 0 || event.clientY < 3) {
          modalLeadMagnetShow.value = true;
          store.state.StLeadConversion = true;
          cookies.set("dvmLeadMagnet", "true", { expires: "3d" });
        }
      }
    }

    return {
      countVisitCookie,
      showExit,
      visitModalId,
      leadMagnetCookie,
      leadMagnetModalId,
      leadConversionPhone,
      modalLeadMagnetShow,
      modal10VisitsShow,
      preload,
    };
  },
};
</script>

<style lang="scss">
body{
	bg: #222;
}
.br {
  border-radius: 3px 0px 0px 3px !important;
}

.ddd {
  background-color: red;
}
.bgimg {
  background: url(/img/kitchen/husk.png) repeat left top;
}



</style>
