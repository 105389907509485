<template>
  <div style="position: relative" class="uk-section bg-white">
    <div class="topSplash" v-if="$q.platform.is.mobile"></div>

    <div class="uk-container">
      <h3 class="text-h5 text-center">
        <span class="uk-text-primary">Кухни на заказ</span>
        – это лучший выбор для тех, кто хочет получить качественный продукт
        воплотив свои идеи, инновационные решения и достижения современных
        производителей кухонных принадлежностей. Кухня под заказ должна быть не
        только красива, но и функциональна.
      </h3>
      <div uk-grid>
        <div class="uk-width-1-2@m">
          <q-img src="/img/kitchen/article/logo.png" :ratio="1" />
        </div>

        <div class="uk-width-1-2@m uk-flex uk-flex-middle">
          <div>
            <hr class="uk-divider-icon" />
            <p class="uk-text-large">
              В Комсомольске-на-Амуре вы сможете найти десятки компаний, которые смогут
              предложить Вам что-то, но вы хотите именно Вашу кухню, созданную
              для Вас из лучших материалов, с использованием лучшей фурнитуры.
              Сокол – это команда профессионалов, которая разработает Вам
              дизайн кухни, а также поможет реализовать его в жизнь.
            </p>
            <hr class="uk-divider-icon" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--  <div class="uk-container uk-container-large">-->
  <!--    <div uk-grid>-->

  <!--      <div class=" uk-width-1-2@m  ">-->
  <!--        <q-img-->
  <!--            src="/img/kitchen/article/1.png"-->
  <!--            :ratio="1"-->
  <!--        />-->
  <!--      </div>-->

  <!--      <div class="uk-width-1-2@m  uk-flex uk-flex-middle">-->
  <!--        <q-img-->
  <!--            src="/img/kitchen/article/2.png"-->
  <!--            :ratio="1"-->
  <!--        />-->
  <!--      </div>-->

  <!--    </div>-->
  <!--  </div>-->
</template>

<script>
export default {
  name: "DvmArticle",
};
</script>

<style scoped>
.topSplash {
  height: 4px;
  width: 100%;
  position: absolute;
  content: "";
  z-index: 100;
  background: url(/img/test/how-we-work-people-plash2.png) repeat-x;
  top: -4px;
  left: 0;
}
</style>
