<template>
  <div class="preloader-background">
    <div class="preloader-wrapper big">
      <div id="loading">
        <div id="loading--inner" class="loading--inner">
          <div class="loading--box upper loading--box-animate" style="left: 20px; bottom: 0; animation-delay: .7s"></div>
          <div class="loading--box loading--box-animate" style="left: 32px; bottom: 0; animation-delay: .6s"></div>
          <div class="loading--box dbl-width loading--box-animate" style="left: 44px; bottom: 0; animation-delay: .5s"></div>
          <div class="loading--box sink loading--box-animate" style="left: 50px; bottom: 11px; animation-delay: .5s"></div>
          <div class="loading--box hang loading--box-animate" style="left: 44px; top: 0; animation-delay: .4s"></div>
          <div class="loading--box hang loading--box-animate" style="left: 56px; top: 0; animation-delay: .3s"></div>
          <div class="loading--box loading--box-animate" style="left: 68px; bottom: 0; animation-delay: .1s"></div>
          <div class="loading--box upper loading--box-animate" style="left: 68px; top: 0; animation-delay: .1s"></div>
          <div class="loading--box dbl-height loading--box-animate" style="left: 80px; bottom: 0;"></div>
          <div class="loading--text">DVM - 2022</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DvmPreloader"
}
</script>

<style scoped>
.loading--inner {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 110px;
  height: 30px;
  transform: translate(-50%, -50%);

}
.loading--inner:after {
  position: absolute;
  content: '';
  height: 1px;
  bottom: -2px;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.2);
}
.loading--inner .loading--box {
  position: absolute;
  width: 10px;
  height: 10px;
  background: rgba(255, 235, 59);
  opacity: 1;
}
.loading--inner .loading--box.dbl-width {
  width: 22px;
}
.loading--inner .loading--box.upper {
  height: 18px;
}
.loading--inner .loading--box.hang {
  height: 8px;
}
.loading--inner .loading--box.sink {
  height: 5px;
  width: 2px;
}
.loading--inner .loading--box.dbl-height {
  height: 30px;
}
.loading--inner .loading--box-animate {
  animation: loading--box-animation 3s infinite ease-in-out;
}
.loading--inner .loading--text {
  color: rgba(255, 235, 59);
  font-size: 12px;
  position: absolute;
  line-height: 1.6em;
  top: 100%;
  left: 0;
  right: 0;
  text-align: center;
  animation: loading--text-animation 2s infinite;
}

@keyframes loading--text-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes loading--box-animation {
  0% {
    opacity: 0;
    transform: translate(-20px, 0);
  }
  10% {
    opacity: 0;
    transform: translate(-20px, 0);
  }
  25% {
    opacity: 1;
    transform: translate(0, 0);
  }
  75% {
    opacity: 1;
    transform: translate(0, 0);
  }
  90% {
    opacity: 0;
    transform: translate(20px, 0);
  }
  100% {
    opacity: 0;
    transform: translate(20px, 0);
  }
}
.preloader-background {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3a3a3a;
  overflow:  hidden;

  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.zin {
  z-index: 9999;
  position: relative;
}
</style>