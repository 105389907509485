<template>
  <form class="q-px-lg" v-on:submit.prevent>
    <div class="uk-inline uk-width-1-1@s">
      <span
        class="uk-form-icon uk-form-icon-flip color-b"
        uk-icon="icon: receiver"
      ></span>
      <input v-model="inputValSp" class="uk-hidden" type="text" id="mail" />
      <input
        class="uk-input uk-border-rounded uk-margin"
        @input="inp"
        @click="showNumder"
        :class="{
          'text-primary': this.inputVal.length === 11,
          'uk-form-danger': this.inputVal.length >= 12,
          'uk-animation-shake': this.inputVal.length >= 12,
        }"
        v-model="inputVal"
        type="tel"
        placeholder="Ваш телефон"
      />
    </div>
    <div class="uk-text-center">
      <q-btn
        @click="sm"
        v-if="valid"
        color="teal-14"
        :class="{ 'uk-button-danger': this.inputVal.length > 11 }"
        class="uk-margin uk-animation-fade uk-animation-slide-top-medium text-black"
      >
        {{ this.$props.btnTitle }}
      </q-btn>
    </div>
  </form>
</template>

<script>
import { useStore } from "vuex";
import { ref } from "vue";
import axios from "axios";
import { useQuasar } from "quasar";

export default {
  name: "FormSend",
  props: {
    btnTitle: {
      type: String,
      required: true,
    },
    skidka: {
      type: Number,
      required: true,
    },
    resource: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const inputVal = ref(
      store.state.StConversionPhone
        ? store.state.StConversionPhone.toString().slice(0, -4)
        : ""
    );
    const inputValSp = ref("");
    const valid = ref(false);
    const doneSend = ref(false);
    const $q = useQuasar();
    const cookies = $q.cookies;

    const showNumder = () => {
      if (store.state.StConversionPhone && inputVal.value.length < 11) {
        if ($q.platform.is.mobile) {
          $q.notify({
            progress: true,
            message: Number(11 - inputVal.value.length === 1)
              ? "Мы опредилили Ваш номер телефона, введите последнюю цифру"
              : "Мы опредилили Ваш номер телефона, введите последние " +
                Number(11 - inputVal.value.length) +
                " цифры",
            color: "primary",
            position: "top",
          });
        } else {
          $q.notify({
            progress: true,
            message: Number(11 - inputVal.value.length === 1)
              ? "Мы опредилили Ваш номер телефона, введите последнюю цифру"
              : "Мы опредилили Ваш номер телефона, введите последние " +
                Number(11 - inputVal.value.length) +
                " цифры",
            color: "primary",
            position: "bottom",
          });
        }
      }
    };

    function sm() {
      if (
        inputVal.value.length >= 10 &&
        inputVal.value.length <= 11 &&
        inputValSp.value === ""
      ) {
        if (props.resource === "LeadMagnet") {
          store.state.StConversionLeadMagnet = true;
        } else {
          store.state.StConversionModal = true;
          store.state.StConversionZamer = true;
        }
        store.state.qtel = inputVal.value;

        cookies.set("leadConversionPhone", inputVal.value, { expires: "30d" });
        cookies.set("leadConversion", "true", { expires: "30d" });

        axios
          .post("https://api.sokolmeb.ru", {
            quiz: false,
            tel: true,
            telKi: inputVal.value,
            skidka: props.skidka,
            resource: props.resource,
          })

          .then(
            (store.state.StLeadConversion = true),
		          ym(95205055,'reachGoal','allConvertion'),
            (doneSend.value = true),
            (store.state.quizDone = false),
            function (response) {}
          )
          .catch(function (error) {
            console.log(error);
          });
      }
    }

    function inp() {
      if (this.inputVal.length >= 13) {
        this.inputVal = "";
      }
      if (this.inputVal.length === 11) {
        this.valid = true;
      }
      this.inputVal = this.inputVal.toString().replace(/[^\d]/g, "");
    }
    return {
      inp,
      sm,
      inputValSp,
      inputVal,
      valid,
      doneSend,
      showNumder,
    };
  },
};
</script>

<style scoped></style>
