<template>
<div >
  {{ idPage }}
  <hr>

  <ul>
    <li v-for="items in kitchen3.projects" :key="items.name">
      <img :src="items.src" alt="">
    </li>
  </ul>

</div>

  <hr>
  <div>{{ $route.params.id }}</div>
  {{ kitchens }}
  <br>
  {{idPage}}
</template>

<script>
import {kitchenData} from "../settings/projects";
import {useRoute, useRouter} from "vue-router";
import {useAttrs} from "vue";




export default {
  name: "User",
  props: {
    item: Object
  },
  setup() {
    const router = useRouter()
    const route = useRoute()

    let zero2 // Typescript / ECMAScript syntax, preferred to the "var zero;" syntax.
    if (route.params.id < 10) {
      zero2 = route.params.id.replace('0', '')
    } else {
      zero2 = route.params.id;
    }
    const idPage = zero2
    const kitchen3 = {
      projects : {      }
    }

    const kitchens = kitchenData.find(kitchens => kitchens.id === idPage)

    if (kitchens === undefined)
    {
      router.push({name: '404'})
    } else {
      for (let i = 1; i < kitchens.filesCount+1; i++) {
        let zero // Typescript / ECMAScript syntax, preferred to the "var zero;" syntax.
        if (i < 10) {
          zero = '0';
        } else {
          zero = '';
        }
        const b = zero + i.toString()
        kitchen3.projects[b] = {
          src: '/img/kitchen/'+ route.params.id +'/'+ b +'.jpg',
          name: i,
        }
      }
    }



    return {
      kitchens, kitchenData, idPage, kitchen3
    }

  }
}
</script>

<style scoped>

</style>