<template>
  <div class="q-px-lg q-pb-md">
    <h2-title :title="title" color="text-teal-14" />
    <q-timeline
      color="teal-14"
      :layout="layout"
      class="q-pl-xl q-pr-xl"
      id="timeline"
    >
      <q-timeline-entry
        title="Заявка на выезд специалиста"
        subtitle="День 1"
        icon="far fa-calendar-check"
        side="left"
      >
        <div>
          Оставьте заявку, вам перезвонит наш специалист и договорится о замере
          в удобное для Вас время
        </div>
        <div class="text-center q-mt-md">
          <q-btn
            label="Оставить заявку"
            @click="sm = !sm"
          />
        </div>
      </q-timeline-entry>
      <q-timeline-entry
        title="Выезд замерщика"
        subtitle="День 2 - 5"
        icon="fas fa-car"
        side="right"
      >
        <div>
          К вам домой приедет специалист, и зафиксирует все измерения, расположение окон, дверей,
          розеток. После этого, передаст данные в офис (Орджоникидзе 11), и с вами свяжется менеджер, который будет вести ваш проект
        </div>
      </q-timeline-entry>
      <q-timeline-entry
        title="Встреча в офисе"
        subtitle="День 6"
        icon="far fa-file-word"
        side="left"
      >
        <div>
	        Получив необходимые замеры дизайнер свяжется с Вами, и пригласит в офис, где продемонстрирует материалы, создаст 3D проект, озвучит точную стоимость и заключит договор
        </div>
      </q-timeline-entry>
      <q-timeline-entry
        title="Производство мебели"
        subtitle="День 7 - 50"
        icon="fas fa-cogs"
        color="orange"
        side="right"
      >
        <div>
          В зависимости от сложности изделия, для производства нам необходимо от
          15 до 40 дней с момента заключения договора.
        </div>
      </q-timeline-entry>
      <q-timeline-entry
        title="Доставка и монтаж"
        subtitle="День 50 - 54"
        icon="fas fa-hammer"
        side="left"
      >
        <div>
          К вам бесплатно доставят Ваше изделие и поднимут в квартиру. На
          следующий или в этот-же день приедут монтажники, и произведут установку. В
          зависимости от сложности изделия, на монтаж требуется от 1 до 5 дней
        </div>
      </q-timeline-entry>

      <q-timeline-entry
        title="Сдача изделия"
        subtitle="День 55"
        color="green"
        icon="done_all"
        side="right"
      >
        <div>
          В день окончания монтажа изделия, или на следующий день Вам необходимо посетить наш офис (Орджоникидзе 11) для осуществления приёма-сдачи изделия. После подписания
          актов происходит окончательный расчет.
        </div>
      </q-timeline-entry>
    </q-timeline>
  </div>

  <modal-zamer :state="sm" />
</template>

<script>
import { useQuasar } from "quasar";
import { computed, ref } from "vue";
import H2Title from "../../UiComponents/h2-title";
import ModalZamer from "../../UiComponents/ModalZamer";
export default {
  components: { ModalZamer, H2Title },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  name: "TimeLine",
  setup() {
    const sm = ref(false);
    const $q = useQuasar();

    return {
      sm,
      layout: computed(() => {
        return $q.screen.lt.sm
          ? "dense"
          : $q.screen.lt.md
          ? "comfortable"
          : "loose";
      }),
    };
  },
};
</script>

<style scoped lang="scss">
.q-btn {
	color: #222;
	background: rgb(246,195,127);
	background: linear-gradient(137deg, rgba(246,195,127,1) 7%, rgba(186,146,90,1) 24%, rgba(151,111,64,1) 42%, rgba(246,195,127,1) 75%, rgba(246,195,127,1) 85%);
	//display: inline-block;
	//text-transform: uppercase;
	//
	//box-shadow: 0 2px 5px 0 rgba(3,6,26,0.15);
	//transition: .5s all ease-in-out;
	&:hover {
		color: #fff;
		outline: 2px solid rgb(93, 74, 50);
	}
}
</style>
