<template>
  <h3-title :title="title"/>

  <div class="row justify-center">
    <div class="col-lg-3 col-xl-3 col-md-3 col-sm-5 col-xs-6 " v-for="item in question" :key="item.id">

      <q-card
          @click='setqval(item)'
          class="my-card q-ma-sm curPoint"
      >
        <q-img
            :src="item.img"
            :alt="item.title + ' кухня | DVM - Мебель в Краснодаре'"

            fit="contain"
            loading = "eager"
        >
          <div class="absolute-top text-subtitle text-center">
            {{ item.title }}
          </div>
          <div class="absolute-center" style="background-color:transparent;" >
            <q-icon
                style="font-size: 5em"
                class="text-primary"
                name="done"
                :class="{'uk-hidden': item.title!==this.$store.state.q1val }"
            />
          </div>



        </q-img>
      </q-card>

    </div>

  </div>


</template>

<script>
import { useStore } from 'vuex'
import H3Title from "../../../UiComponents/h3-title";


export default {
  name: "typeKitchen",
  components: {H3Title},
  props:{
    title: {
      type: String,
      required: true
    },
  },
  setup() {
    const store = useStore()
    const question = [
      {
        active: false,
        id: 1,
        title: 'Прямая',
        description: 'фырмыр',
        img: 'img/quiz/1.webp'
      },
      {
        active: false,
        id: 2,
        title: 'Угловая (левая)',
        description: '312111',
        img: 'img/quiz/2-l.webp'
      },
      {
        active: false,
        id: 3,
        title: 'Угловая (правая)',
        description: '312222',
        img: 'img/quiz/2.webp'
      },
      {
        active: false,
        id: 4,
        title: 'П - образная',
        description: '312333',
        img: 'img/quiz/3.webp'
      }
    ]


    function setqval(arg) {
      store.state.q1val = arg.title
      store.state.q1id = arg.id
      store.state.cTest = store.state.cTest + 1



      const fttimeout = () => {
        UIkit.switcher('#quiz-switch').show(store.state.cTest-1)

      }
      setTimeout(fttimeout, 300)


    }
    return{
      question,
      setqval
    }
  }
}
</script>

<style scoped>
.curPoint {
  cursor: pointer!important;
}
.text-subtitle {
  padding: 3px;
}
</style>