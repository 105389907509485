<template>
  <h2-title :title="title" color="text-teal-14" />
  <quiz-home />
</template>

<script>
import H2Title from "../../UiComponents/h2-title";
import QuizHome from "../quiz/quizHome";
export default {
  name: "dvmQuiz",
  components: { QuizHome, H2Title },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    return {};
  },
};
</script>

<style scoped></style>
